import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import Container from '../layout/Container';
import IncludedTickPath from '../images/included_coloured_tick.svg';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.primary};
`;

const TitleContainer = styled(Container)`
  margin: 0 auto;
  p {
    margin: 0 auto;
  }
`

const FlexContainer = styled.div`
  max-width: 1440px;
  display: flex;
  margin: 0 auto;
  align-items: stretch;
  flex-direction: column;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    flex-direction: row;
    gap: 1rem;
  `};
`;

const ListContainer = styled.ul`
  text-align: left;
  li {
    background: url(${IncludedTickPath}) no-repeat left center;
    padding: ${({ theme }) =>
      `${theme.sizes.lvl0} ${theme.sizes.lvl1} ${theme.sizes.lvl0} ${theme.sizes.lvl5}`};
    list-style-type: none;
    position: relative;
    color: #FFF;
    font-size: 1.5rem;
    &:before {
      position: absolute;
      left: 0;
      top: 25px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      content: 'check_circle';
      font-size: 2rem;
      font-family: 'Material Icons';
      color: #FFF;
    }
  }
`;

const FeaturedGiftSection = () => {
  const {t} = useTranslation();

  return (
    <div>
    <TitleContainer>
      <p className="h3 text-center mb-0">In this <strong>transformative</strong> <u>45 minute webinar</u>, Founder of Therapeutic Coaching Alex Howard will share:</p>
    </TitleContainer>
    <Background>
      <FlexContainer>
        <StaticImage width="100%" src={'../images/featured_gifts_twocolumns.jpg'} className="lg:w-1/2 w-full h-80 lg:h-auto" alt="Alex Howard" />&nbsp;
        <Container className="lg:w-1/2 w-full">
          <ListContainer>
            <li>What anxiety actually IS (it's often misunderstood and misdiagnosed)</li>
            <li>The 3 most common forms of anxiety (which nearly everyone suffers from at some point in life) and <strong>how to identify them</strong></li>
            <li><strong>Practical guidance</strong> for working with each type of anxiety that you can apply now to <u>start feeling better right away</u></li>
            <li>Why a typical approach to treating anxiety often makes it WORSE</li>
            <li><strong>How to RESET</strong> your anxiety to experience greater mental, emotional, and physical <u>freedom and peace</u></li>
          </ListContainer>
        </Container>
      </FlexContainer>
    </Background>
    </div>
  );
};

export default FeaturedGiftSection;
