import {PubSub} from "./pubsub"
import Topics from "./pubsub-topics";
import {check} from "./checks";
import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { getAuth } from 'firebase/auth'
import { getFirestore, doc, setDoc } from 'firebase/firestore'

async function fetchClientIp (){
    const functions = getFunctions();
    const getUserIp: () => Promise<HttpsCallableResult<{ip: string, userAgent: string}>> = httpsCallable(functions, 'onCallGetUserIp')
    const response = await getUserIp()
    return response.data.ip;
}

async function handle(_key, _data ) {
    try {
        const auth = getAuth()
        const user = auth.currentUser;

        const db = getFirestore()
        const customerRef = doc(db, `customers/${user.uid}`)
        const customer = {ip: '', email: user.email}
        await check(customer, 'ip', fetchClientIp);
        setDoc(customerRef, customer, { merge: true });
    } catch(e){
        console.log('customerIp handle error')
        console.error(e.message)
    }
}

(function(){
    PubSub.subscribe(Topics.REGISTERED, handle)
})()