import React from 'react';
import styled from 'styled-components';
import Container from '../layout/Container';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const Background = styled(Container)`
  position: relative;
  background-color: ${({ theme }) => theme.colours.white};
`;

const Text = styled.h1`
  font-size: ${({ theme }) => theme.font.sizes.xxl};

  font-family: ${({ theme }) => theme.font.families.title};
  color: ${({ theme }) => theme.colours.black};
  text-align: center;
  font-weight: 400;
  line-height: 130%;
  b {
    font-weight: 700;
  }

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    font-size: ${theme.font.sizes.xxxl};
  `}

  ${({ theme }) => theme.breakpoint('medium')`
    font-size: ${theme.font.sizes.xxxxl};
  `}
`;

const LoginIntroSection = ({title}) => {
  const {t} = useTranslation();
  
  return (
    <Background>
      <Text>
        <b>{title || t('login.intro.title')}</b>
      </Text>
    </Background>
  );
};

export default LoginIntroSection;
