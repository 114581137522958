import {PubSub} from "./pubsub"
import Topics from "./pubsub-topics";
import { isBrowser } from '../utils/index'
import { getAuth, connectAuthEmulator, createUserWithEmailAndPassword, AuthErrorCodes } from 'firebase/auth';

async function handle(key, data){
    PubSub.publish(Topics.START_LOADER)

    try{
        const auth = getAuth()
        if(process.env.GATSBY_EMULATOR && isBrowser && window.location.hostname === "localhost") {
            connectAuthEmulator(auth, "http://localhost:9099");
        }
        const user = await createUserWithEmailAndPassword(auth, data.email, data.password)
        PubSub.publish(Topics.REGISTERED, { user })
    } catch(e) {
        console.log('registration handler error')
        console.error(e)
        if(e.code === AuthErrorCodes.USER_DELETED || e.code === AuthErrorCodes.EMULATOR_CONFIG_FAILED){
            alert(`Please check your email and password then try again.`)
        }
    }

    PubSub.publish(Topics.STOP_LOADER)
}

(function(){
    PubSub.subscribe(Topics.REGISTER, handle)
})()