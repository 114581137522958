import {PubSub} from "./pubsub"
import Topics from "./pubsub-topics";
import { isBrowser } from '../utils/index'
import { getAuth, connectAuthEmulator, sendPasswordResetEmail } from 'firebase/auth';

const REDIRECT_PATH = '/login'

async function handle(key, data){
    PubSub.publish(Topics.START_LOADER)

    try{
        const auth = getAuth()
        if(process.env.GATSBY_EMULATOR && isBrowser && window.location.hostname === "localhost") {
            connectAuthEmulator(auth, "http://localhost:9099");
        }
        await sendPasswordResetEmail(auth, data.email, {
            url: window.location.origin + REDIRECT_PATH
        })
    } catch(e){
        console.error(e)
     }

    PubSub.publish(Topics.STOP_LOADER)
}

(function(){
    PubSub.subscribe(Topics.RESET_PASSWORD, handle)
})()