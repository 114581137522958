import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import Container from '../layout/Container';

const StyledHeader = styled.header`
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const HeaderTitle = styled.h1`
  && {
    color: ${({ theme }) => theme.colours.primary};
    margin: ${({ theme }) => theme.sizes.lvl2} 0;
`;

const HeaderInner = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.sizes.lvl2};
  color: ${({ theme }) => theme.colours.primaryDark};
  text-align: center;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: ${({ theme }) => theme.sizes.lvl2} ${({ theme }) =>
    theme.sizes.lvl6};
  `}
`;

const LogoWrapper = styled.div`
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    margin: 0;
  `}
`;

const Header = ({ siteTitle, children }) => {
  return (
    <StyledHeader>
      <HeaderInner>
        <LogoWrapper>
          <Link to="/">
            <StaticImage
              src="../images/logo.png"
              placeholder="tracedSVG"
              alt={siteTitle}
              width={320}
            />
          </Link>
        </LogoWrapper>

        <HeaderTitle className="h3">{children}</HeaderTitle>
      </HeaderInner>
    </StyledHeader>
  );
};

export default Header;
