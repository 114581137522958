import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import {ColourLevel} from "../types";

import Container from '../layout/Container';
import SpeakersListAndTitle from '../components/SpeakersListAndTitle';
import { useSiteMetaContext } from '../context/SiteMetaContext';

const ExpertsBackground = styled.div`
  background-color: #FAF7F2;
`;

const BonusSpeakersListAndTitle = styled(SpeakersListAndTitle)`
  margin-top: ${({ theme }) => theme.sizes.lvl4};
  ${({ theme }) => theme.breakpoint('desktop')`
    margin-top: 0;
  `}
`;

const AgendaExpertsSection = () => {
  const { hasConferenceStarted } = useSiteMetaContext();
  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "Day" }}
        sort: { fields: data___Date }
      ) {
        nodes {
          id
          data {
            Name
            ShortName
            LongName
            Date
            Session {
              id
              data {
                Name
                Slug
                DateOrder
                Category
                BonusSession
                Content {
                  childMarkdownRemark {
                    html
                  }
                }
                Speaker {
                  id
                  data {
                    Name
                    WebsiteUri
                    Avatar {
                      localFiles {
                        childImageSharp {
                          gatsbyImageData(width: 320)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const dates = allAirtable?.nodes;

  return (
    <>
      {dates
        .sort(
          (dateA, dateB) =>
            new Date(dateA.data.Date) - new Date(dateB.data.Date)
        )
        .map(({ data, id }, index) => {
          const bonusSpeakers = data.Session.map(
            ({ data: sessionData }) => sessionData
          )
            .filter(({ BonusSession }) => BonusSession)
            .filter(({ Speaker }) => Speaker.length)
            .filter(({ Speaker }) => !!Speaker[0].data.Avatar?.localFiles[0])
            .sort(
              (sessionA, sessionB) => sessionA.DateOrder - sessionB.DateOrder
            )
            .map((data) => ({
              id: data.Speaker[0].id,
              name: data.Speaker[0].data.Name,
              tagline: data.Name,
              image: data.Speaker[0].data.Avatar?.localFiles[0],
              websiteUri: data.Speaker[0].data.WebsiteUri,
              slug: data.Slug,
              content: data.Content,
              category: data.Category,
            }));

          const normalSpeakers = data.Session.map(
            ({ data: sessionData }) => sessionData
          )
            .filter(({ BonusSession }) => !BonusSession)
            .filter(({ Speaker }) => Speaker.length)
            .filter(({ Speaker }) => !!Speaker[0].data.Avatar?.localFiles[0])
            .sort(
              (sessionA, sessionB) => sessionA.DateOrder - sessionB.DateOrder
            )
            .map((data) => ({
              id: data.Speaker[0].id,
              name: data.Speaker[0].data.Name,
              tagline: data.Name,
              image: data.Speaker[0].data.Avatar?.localFiles[0],
              websiteUri: data.Speaker[0].data.WebsiteUri,
              slug: data.Slug,
              content: data.Content,
              category: data.Category,
            }));

          return (
            <ExpertsBackground key={`experts_${id}`}>
              <Container>
                <SpeakersListAndTitle
                  title={`"${data.ShortName}"`}
                  description={data.LongName}
                  speakers={normalSpeakers}
                  colourLevel={ColourLevel.TERTIARY}
                  date={data.Date}
                  day={index + 1}
                  hideBuyButton={!hasConferenceStarted}
                />
                {bonusSpeakers && bonusSpeakers.length > 0 && (
                  <BonusSpeakersListAndTitle
                    title="Bonus Sessions"
                    speakers={bonusSpeakers}
                    colourLevel={ColourLevel.TERTIARY}
                    date={data.Date}
                    hideBuyButton={!hasConferenceStarted}
                  />
                )}
              </Container>
            </ExpertsBackground>
          );
        })}
    </>
  );
};

export default AgendaExpertsSection;
