import React, {useState, useContext, ReactNode} from 'react';
import styled, {ThemeContext} from 'styled-components';

import {ColourLevel} from '../types';
import Button from './Button';
import IncludedTickPath from '../images/included_tick.svg';
import ExcludedCrossPath from '../images/excluded_cross.svg';

const ListContainer = styled.ul`
  position: relative;
  padding: 0;
  text-align: left;
`;

const Li = styled.li<{ $included?: boolean; $paddingSize: PaddingSize }>`
  background: url(${({$included}) =>
          $included ? IncludedTickPath : ExcludedCrossPath}) no-repeat left center;
  list-style: none;
  margin: 0;
  vertical-align: middle;
  font-weight: 400;
  -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
               break-inside: avoid;
  ${({$included}) =>
          !$included
                  ? 'text-decoration: line-through;color: rgba(0, 0, 0, 0.15);'
                  : ''};

  &:last-child {
    margin-bottom: 0;
  }

  b {
    font-weight: 700;
  }

  ${({$paddingSize, theme}) => {
    switch ($paddingSize) {
      case PaddingSize.XS:
        return `padding: ${theme.sizes.lvl0} ${theme.sizes.lvl1} ${theme.sizes.lvl0} ${theme.sizes.lvl6};`;
      case PaddingSize.SM:
        return `padding: ${theme.sizes.lvl1} ${theme.sizes.lvl1} ${theme.sizes.lvl1} ${theme.sizes.lvl6};`;
      default:
      case PaddingSize.MD:
        return `padding: ${theme.sizes.lvl2} ${theme.sizes.lvl1} ${theme.sizes.lvl2} ${theme.sizes.lvl6};`;
      case PaddingSize.LG:
        return `padding: ${theme.sizes.lvl3} ${theme.sizes.lvl1} ${theme.sizes.lvl3} ${theme.sizes.lvl6};`;
    }
  }}
`;

const LiMaterialIcon = styled(Li)<{
    $materialIcon: string;
    $paddingSize: PaddingSize;
}>`

  background: none;
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: '${({$materialIcon}) => $materialIcon}';
    font-size: 1.75rem;
    font-family: 'Material Icons';
    color: ${({theme}) => theme.colours.primary};
    margin-right: 10px;
  }
`;
const Fade = styled.div`
  position: absolute;
  height: ${({theme}) => theme.sizes.lvl10};
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(transparent 10%, white 75%);
`;

const ShowMoreButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
`;

enum PaddingSize {
    XS = 'XS',
    SM = 'SM',
    MD = 'MD',
    LG = 'LG',
}

type ListItem = {
    content?: ReactNode;
    materialIcon?: string;
    included?: boolean;
};

interface IconListProps {
    className?: string;
    items: ListItem[];
    showMoreAfterXItems?: number;
    paddingSize?: PaddingSize;
}

const IconList = ({
                      className,
                      items,
                      showMoreAfterXItems,
                      paddingSize = PaddingSize.MD,
                  }: IconListProps) => {
    const [showXItems, setShowXItems] = useState<number>(
        showMoreAfterXItems || items.length
    );
    const theme = useContext(ThemeContext);

    const filteredListItems = items.slice(0, showXItems);

    return (
        <>
            <ListContainer className={className}>
                {filteredListItems.map((listItem, index) =>
                    listItem.materialIcon ? (
                        <LiMaterialIcon
                            key={index}
                            $paddingSize={paddingSize}
                            $included={listItem.included}
                            $materialIcon={listItem.materialIcon}
                        >
                            {listItem.content}
                        </LiMaterialIcon>
                    ) : (
                        <Li
                            key={index}
                            $paddingSize={paddingSize}
                            $included={listItem.included}
                        >
                            {listItem.content}
                        </Li>
                    )
                )}
                {showXItems < items.length && <Fade/>}
            </ListContainer>
            {showXItems < items.length && (
                <ShowMoreButton
                    onClick={() => setShowXItems(items.length)}
                    fluid
                    colourLevel={ColourLevel.SECONDARY}
                    icon="expand_more"
                    outlineColour={theme.colours.secondaryLight}
                    backgroundColour={theme.colours.secondaryLight}
                    textColour={theme.colours.secondary}
                >
                    Show more
                </ShowMoreButton>
            )}
        </>
    );
};

export {PaddingSize, ListItem, IconList};
export default IconList;
