import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../layout/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import { useUserContext } from '../context/UserContext';

const Container = styled.div`
    margin: 0 auto;
    padding: 0 1rem;
    ${({theme}) => theme.breakpoint('tabletPortrait')`
        max-width: 480px;
    `};
    ${({theme}) => theme.breakpoint('tabletPortrait')`
        max-width: 600px;
    `};
    ${({theme}) => theme.breakpoint('tabletIpadPortrait')`
        max-width: 960px;
    `};  
    ${({theme}) => theme.breakpoint('desktop')`
    max-width: 1279px;
    `};
    ${({theme}) => theme.breakpoint('medium')`
        max-width: 1440px;
    `};
`;

const OfferPage = ({ location }) => {

    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const [hasRegistered, setHasRegistered] = useState(false);
    const {
        register: registerForConference
      } = useUserContext();

    const handleRegister = async (data) => {
        await registerForConference(data);
      };

      useEffect(() => {
        if (params?.inf_field_Email && !hasRegistered) {
          setHasRegistered(true);
          const affiliateId = localStorage.getItem('superconferenceAffiliate')
          handleRegister({
            infusionsoftId: params.contactId,
            affiliateId,
            email: params.inf_field_Email.toLowerCase(),
            firstName: params.inf_field_FirstName,
          });
        }
      }, [params, handleRegister]);

    return (
        <Layout>
            <div className="bg-secondary py-4">
                <Container>
                    <StaticImage
                        src="../images/logo-white.png"
                        placeholder="tracedSVG"
                        width={250}
                    />
                    <div className="text-center py-4">
                        <div className="sm:block hidden">
                            <h4 className="text-white h4 mb-2">You're almost done...</h4>
                            <StaticImage
                                src="../images/complete-bar.png"
                                placeholder="tracedSVG"
                                width={730}
                            />
                        </div>
                        <div className="sm:hidden block">
                            <StaticImage
                                src="../images/complete-bar-mobile.png"
                                placeholder="tracedSVG"
                                width={730}
                            />
                        </div>
                    </div>
                </Container>
            </div>

            <div className="bg-[#193F65] sm:bg-primary py-6 sm:py-8">
                <Container>
                    <h4 className="text-white text-xl sm:text-2xl mb-2 text-center m-0 !font-bold">You're registered!✓</h4>
                    <h4 className="text-white text-center sm:leading-normal leading-tight text-base sm:text-lg">You have access to watch each day's sessions for free for <span className="font-bold lowercase">24 hours</span> <u>starting November 28th</u></h4>
                </Container>
            </div>

            <div className="bg-[#F5F3F5] py-8 sm:py-16 bg-none bg-repeat-x bg-[center_115%] md:bg-[url('https://i.postimg.cc/Df5RfYMb/pattern.png')]">
                <Container className="text-center">
                    <h4 className="h4 text-[#0F3860] text-center mb-4">Want to watch at your own pace? Don't miss out on this <span className="font-bold !lowercase">amazing offer</span></h4>
                    <h1 className="h1 text-[#0F3860] text-center !leading-none sm:!leading-[76px]">TODAY ONLY<br/>LIFETIME ACCESS<span className="text-primary block">JUST $97</span></h1>
                    <h4 className="h3 text-[#0F3860] mt-4 sm:mt-8 text-center"><span className="font-bold text-primary !lowercase">Save 75%</span> when you buy in <span className="font-bold text-primary !lowercase">the next 10 minutes</span></h4>
                    <div className="w-full md:w-1/3 mx-auto">
                        <a className="mt-8 !w-full block bg-primary rounded py-4 text-white shadow-lg !font-bold !text-[18px] hover:bg-secondary" href="https://xz684.infusionsoft.com/app/orderForms/afcc3660-1b4e-495a-9c0b-07205da9c0f2">Buy Now</a>
                        <a className="mt-4 !w-full block rounded py-4 text-[#0F3860] border border-gray-300 !text-[18px] hover:bg-white" href="/thankyou">No, thanks. Complete my registration</a>
                    </div>
                    <div className="sm:block hidden">
                        <StaticImage
                            src="../images/oto-devices.png"
                            placeholder="tracedSVG"
                            width={1145}
                        />
                    </div>
                    <div className="sm:hidden block">
                        <StaticImage
                            src="../images/oto-devices-mobile.png"
                            placeholder="tracedSVG"
                            width={1145}
                        />
                    </div>
                </Container>
            </div>

            <div className="bg-secondary sm:-mt-48 -mt-24 md:pt-40 pt-20 pb-6">
                <Container>
                    <h3 className="text-white h3 text-center"><span className="text-primary !font-bold !lowercase">Save 75%</span> when you buy in <span className="text-primary !font-bold !lowercase">the next 10 minutes</span></h3>
                </Container>
            </div>

            <div className="bg-[#193F65] py-12">
                <Container>
                    <div className="w-full md:w-1/3 mx-auto text-center">
                        <a className="mt-8 !w-full block bg-primary rounded py-4 text-white shadow-lg !text-[18px] !font-bold hover:bg-secondary" href="https://xz684.infusionsoft.com/app/orderForms/afcc3660-1b4e-495a-9c0b-07205da9c0f2">Buy Now</a>
                        <a className="mt-4 !w-full block rounded py-4 text-[#0F3860] bg-gray-300 mb-6 !text-[18px] hover:bg-white" href="/thankyou">No, thanks. Complete my registration</a>
                        <StaticImage
                            src="../images/payment-methods.png"
                            placeholder="tracedSVG"
                            width={274}
                        />
                    </div>
                </Container>
            </div>

        </Layout>
    )
  }

  export default OfferPage;
