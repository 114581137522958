import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';

import { ColourLevel } from '../../types';
import GiftSvgPath from '../../images/gift.svg';
import Container from '../../layout/Container';
import SpeakerCard from '../../components/Speaker/SpeakerCard';
import Carousel from '../../components/Carousel';
import { useUserContext } from '../../context/UserContext';
import {useTranslation} from 'gatsby-plugin-react-i18next';


const Background = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const Inner = styled(Container)`
  text-align: center;
`;

const Title = styled.h1`
  && {
    font-weight: 700;
    line-height: 1.1;
  }
`;

const Subtitle = styled.h2`
  && {
    margin-bottom: ${({ theme }) => theme.sizes.lvl6};
  }
`;

const GiftCardsDesktop = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${({ theme }) => theme.sizes.lvl5};
    grid-row-gap: ${({ theme }) => theme.sizes.lvl5};
  `};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    grid-template-columns: repeat(3, 1fr);
  `};
`;

const MobileOnly = styled.div`
  display: block;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    display: none;
  `};
`;

const LandingGiftsSection = () => {
  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "Session" }, data: { Gift: { eq: true } } }
        sort: { fields: data___GiftOrder }
      ) {
        edges {
          node {
            data {
              Name
              Content {
                childMarkdownRemark {
                  html
                }
              }
              Tagline {
                childMarkdownRemark {
                  html
                }
              }
              Speaker {
                id
                data {
                  Name
                  Avatar {
                    localFiles {
                      childImageSharp {
                        gatsbyImageData(width: 320)
                      }
                    }
                  }
                }
              }
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 320)
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `);
  const { isRegistered } = useUserContext();

  const gifts = allAirtable?.edges?.map((edge) => edge?.node);
  const giftElements = gifts.map(({ data }) => {
      return (
        <SpeakerCard
          colourLevel={ColourLevel.TERTIARY}
          key={`gift_${data.Name}`}
          title={data.Name}
          subtitle={data.Speaker[0].data.Name}
          image={data.Speaker[0].data.Avatar.localFiles[0]}
          content={data.Content}
          hideBuyButton
        />
      );
  });

  const {t} = useTranslation();

  return (
    <Background id="freegifts">
      <Inner>
        <GiftSvgPath className="w-24 h-24 mx-auto mb-8" />
        <Title className="h2">{t('index.freeGifts.title')}</Title>
        <Subtitle className="h4">
          {isRegistered ? (
            <>
              {t('index.freeGifts.accessFreeGift')} <Link to="/thankyou">{t('common:here')}</Link>
            </>
          ) : (
            t('index.freeGifts.subtitle')
          )}
        </Subtitle>
        <MobileOnly>
          <Carousel items={giftElements} showDots />
        </MobileOnly>
        <GiftCardsDesktop>{giftElements}</GiftCardsDesktop>
      </Inner>
    </Background>
  );
};

export default LandingGiftsSection;
