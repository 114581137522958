import { connectAuthEmulator, getAuth, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore, doc, collection, getDoc, setDoc } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

import config from '../../firebaseConfig';
import {isASC} from "../utils";

const namespace  = process.env.GATSBY_REACT_I18N_NAMESPACE
const isBrowser = typeof window !== 'undefined';

class Firebase {
    constructor() {
        /* Helper */
        this.apiUrl = config.apiUrl;

        /* Firebase APIs */
        this.auth = getAuth();
        this.db = getFirestore();
        this.functions = getFunctions();
        this.storage = getStorage();

        if(process.env.GATSBY_EMULATOR && isBrowser && window.location.hostname === "localhost"){
            connectAuthEmulator(this.auth, "http://localhost:9099");
            connectFirestoreEmulator(this.db, "localhost", 8080);
            connectFunctionsEmulator(this.functions, "localhost", 5001);
        }

        /* Storage Refs */
        let storageNS = namespace === 'asc' ? 'ASC_' : '';

        // Points to the root reference
        this.storageTranscriptsRef = ref(this.storage, storageNS+'transcripts');
        this.storageAudioRef = ref(this.storage, storageNS+'audio');
        this.storageFeaturedGiftRef = ref(this.storage, '/gifts/Quit The Conflict Guide.pdf');
    }

    // *** Auth API ***
    doCreateUserWithEmailAndPassword = ({
                                            email,
                                            password,
                                            firstName,
                                            lastName,
                                        }) => {

        return createUserWithEmailAndPassword(this.auth, email, password).then((userCred) => {
            const payload = {
                firstName,
                lastName,
                email,
            };

            const registrationRef = this.registration(userCred.user.email)
                getDoc(registrationRef).then((snapshot) => {
                    if (!isASC && snapshot.exists()) {
                        const registrationData = snapshot.data();
                        payload.affiliateId = registrationData.affiliateId || null;
                        payload.isFb = registrationData.isFb || null;
                        payload.infusionsoft_id = registrationData.infusionsoftId || null;
                    }
                    const customerRef = this.customer(userCred.user.uid)
                    setDoc(customerRef, payload, {merge: true})
                        .then(() =>
                            fetch(`${this.apiUrl}/setUserIp`, {
                                method: 'POST',
                                mode: 'no-cors', // 'cors' by default
                                body: userCred.user.uid,
                            }).catch((err) => console.error('Error recording user IP', err))
                        );
                });
        });
    }

    doSignOut = () => signOut(this.auth);

    doPasswordReset = (email, config) => sendPasswordResetEmail(this.auth, email, config);

    // *** User API ***
    customers = () => collection(this.db, 'customers');
    customer = (uid) => doc(this.db, `customers/${uid}`);
    registrations = () => collection(this.db, 'registrations');
    registration = (email) => doc(this.db, `registrations/${email}`);
    createPaidCustomer = () => httpsCallable(this.functions, 'createPaidCustomer');
    deletePaidCustomer = () => httpsCallable(this.functions, 'deletePaidCustomer');

    // *** Payment API ***
    customerStripePayments = (uid) =>
        collection(this.customer(uid), 'stripe_payments');
    customerPaypalPayments = (uid) =>
        collection(this.customer(uid), 'paypal_payments');
    paypalCreateOrder = () => httpsCallable(this.functions, 'createPaypalPayment');
    paypalConfirmOrder = () => httpsCallable(this.functions, 'confirmPaypalPayment');
    exportCustomers = () => httpsCallable(this.functions, 'exportCustomers');

    // *** Storage API ***
    getTranscriptDownloadLink = (fileName) => getDownloadURL(ref(this.storageTranscriptsRef, fileName));
    getAudioDownloadLink = (fileName) => getDownloadURL(ref(this.storageAudioRef, fileName));
    getFeaturedGiftDownloadLink = () => getDownloadURL(this.storageFeaturedGiftRef);
}

let firebase;

function getFirebase(app, auth, database) {
    if (!firebase) {
        firebase = new Firebase(app, auth, database);
    }

    return firebase;
}

export {getFirebase};
export default getFirebase;
