import React from 'react';
import { graphql } from 'gatsby';
import SalesLayout from '../layout/SalesLayout';
import Seo from '../components/Seo';
import ResetPasswordForm from '../components/ResetPasswordForm';
import {useTranslation} from 'gatsby-plugin-react-i18next';


const ResetPasswordPage = () => {
  const {t} = useTranslation();

  return (
    <SalesLayout>
      <Seo title={t('resetPassword.meta.title')} />
        <div className="bg-secondary py-24 space-y-8">
            <h1 className="text-center text-white text-4xl sm:text-5xl md:text-7xl font-extrabold">{t('resetPassword.title')}</h1>
            <ResetPasswordForm />
        </div>
    </SalesLayout>
  )
};

export default ResetPasswordPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;