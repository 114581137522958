import {graphql, navigate} from 'gatsby';
import { differenceInCalendarDays } from 'date-fns';

import { useUserContext } from '../context/UserContext';
import { useSiteMetaContext } from '../context/SiteMetaContext';

const getSlug = (conferenceStartTime) =>
  differenceInCalendarDays(new Date(), conferenceStartTime) + 1;

const DayPage = () => {
  const { hasPaidForProduct, isPaid } = useUserContext();
  const {
    conferenceStartTime,
    replayStartTime,
    isConferenceLive,
    hasReplayStarted,
  } = useSiteMetaContext();

  if (typeof window === 'undefined') {
    return null;
  }

  if ((isPaid && hasPaidForProduct('Online')) || !isConferenceLive) {
    navigate('/agenda');
  } else {
    navigate(
      getSlug(
        hasReplayStarted ? replayStartTime : conferenceStartTime
      ).toString()
    );
  }

  return null;
};

export default DayPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;