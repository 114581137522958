import plans from "../constants/plans"

const products = plans[process.env.GATSBY_REACT_I18N_NAMESPACE] || []

export const hasPaidForProductAndNotRefunded = (customer, currentPlans) => {
    if(customer.admin || customer.gift)
        return true

    currentPlans = currentPlans || products
    const onlineProductIds = currentPlans.filter(plan => plan.id !== 'flash').map(plan => plan.productId)

    if(!customer.productId || !customer.productId.length) {
        return false
    }

    // check if customer productId intersects with current plan product ids
    const inProductIds = onlineProductIds.filter(val => customer.productId.includes(val))
    if(!inProductIds.length) {
        return false
    }

    if(!customer.refundProductId || !customer.refundProductId.length) {
        return true
    }

    // check if inProductIds not intersects with customer refundProductId
    const refunded = inProductIds.filter(val => !customer.refundProductId.includes(val))
    if(!refunded.length) {
        return false
    }

    return true
}
