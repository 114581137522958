import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import Container from '../../layout/Container';

const Background = styled.div`
  background-color: white;
  color: ${({ theme }) => theme.colours.text};
`;
const SalesContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
`;

const SalesBenefitContainer = styled.div`
  flex-basis: 100%;
  gap: 1rem;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    flex-basis: 33.333%;
  `}
`;
const SalesBenefitInner = styled.div`
  padding: ${({ theme }) => theme.sizes.lvl1} ${({ theme }) => theme.sizes.lvl3};
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    padding: ${({ theme }) => theme.sizes.lvl6};
  `}
`;
const Icon = styled.div`
  && {
    font-size: ${({ theme }) => theme.font.sizes.xxxxxl};
    color: ${({ theme }) => theme.colours.secondary};
    margin: 0 0 ${({ theme }) => theme.sizes.lvl1} 0;
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      margin: 0 ${({ theme }) => theme.sizes.lvl4} 0 0;
    `}
  }
`;
const Content = styled.div`
  flex: 1;
`;
const Title = styled.h3`
`;

const SalesBenefit = ({ className, icon, title, children }) => {
  return (
    <SalesBenefitContainer className={className}>
      <SalesBenefitInner>
        <Icon className="material-icons">{icon}</Icon>
        <Content>
          <Title className="h4">{title}</Title>
          <p>{children}</p>
        </Content>
      </SalesBenefitInner>
    </SalesBenefitContainer>
  );
};

const SalesBenefitsSection = ({ className }) => {
  const {t} = useTranslation();

  return (
    <Background className={className}>
      <SalesContainer>
        { t('sales.decisionSection.benefits', {returnObjects: true, defaultValue: []}).map(benefit => {
          return (
            <SalesBenefit
            icon={benefit.materialIcon}
            title={benefit.title}>
              {benefit.content}
            </SalesBenefit>
          )
        }) }
      </SalesContainer>
    </Background>
  );
};

export default SalesBenefitsSection;
