import React from 'react';
import { graphql } from 'gatsby';

import SalesLayout from '../layout/SalesLayout';
import Seo from '../components/Seo';
import LoginIntroSection from '../sections/loginIntro';
import LoginFormSection from '../sections/loginForm';
import LoginExtraContentSection from '../sections/loginExtraContent';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const LoginPage = () => {
  const {t} = useTranslation();

  return (
    <SalesLayout>
      <Seo title={t('login.meta.title')} />
      <LoginIntroSection />
      <LoginFormSection />
      <LoginExtraContentSection />
    </SalesLayout>
  )
};

export default LoginPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;