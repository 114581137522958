import React from 'react';
import { graphql } from 'gatsby';

import ThankyouPage from '../sections/thankyouPage';

const Thankyou = ({ location }) => {
  return <ThankyouPage location={location} featuredGift />;
};

export default Thankyou;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;