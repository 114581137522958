/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react';
import './src/global.css'

import { UserContextProvider } from './src/context/UserContext';
import { SiteMetaContextProvider } from './src/context/SiteMetaContext';
import { captureAffiliateIds } from './src/services/affiliate';

import config from './firebaseConfig';
import { initializeApp } from 'firebase/app';
import './src/fe_modules/login'
import './src/fe_modules/register'
import './src/fe_modules/reset-password'
import './src/fe_modules/customerIp'
import './src/fe_modules/loader'

initializeApp(config);

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
  captureAffiliateIds();
};

export const wrapRootElement = ({ element }) => (
    <UserContextProvider>
      <SiteMetaContextProvider>{element}</SiteMetaContextProvider>
    </UserContextProvider>
);
