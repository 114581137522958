import React, {useEffect, useState} from 'react';
import { navigate, Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { useUserContext } from '../context/UserContext';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {PubSub} from "../fe_modules/pubsub"
import Topics from "../fe_modules/pubsub-topics"

import Button from './Button';
import TextInput from './TextInput';
import { useSiteMetaContext } from '../context/SiteMetaContext';
import redirectAfterAuth from '../utils/redirectJourney';

const Wrapper = styled.div`
  width: 100%;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Inner = styled.div`
  width: 100%;
  max-width: ${({ $inline }) => ($inline ? '100%' : '640px')};
  display: block;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: ${({ $inline }) => ($inline ? 'flex' : 'block')};
    justify-content: center;
  `}
`;

const ErrorText = styled.p`
  font-size: ${({ theme }) => theme.sizes.lvl2};
  color: ${({ theme }) => theme.colours.signal.error};
  margin: 0 ${({ theme }) => theme.sizes.lvl1};
`;

const FormControl = styled.div`
  margin: 0 ${({ theme }) => `${theme.sizes.lvl1} ${theme.sizes.lvl2}`};
`;

const Disclaimer = styled.p`
  text-align: center;
  line-height: 150%;
  font-size: ${({ theme }) => theme.font.sizes.xxs};
  margin: 0 auto;
  padding-bottom: ${({ theme }) => theme.sizes.lvl3};
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    font-size: ${({ theme }) => theme.font.sizes.xs};
  `}
`;

const SigninForm = () => {
  const siteMetaContext = useSiteMetaContext()
  const {t} = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t('common:registrationForm.email.validationMsg'))
      .email(t('common:registrationForm.email.validationMsg'))
      .trim()
      .label(t('common:registrationForm.email.label')),
    password: yup
      .string()
      .required(t('common:loginForm.password.validationMsg'))
      .min(6, t('common:loginForm.password.validationMinMsg'))
      .label(t('common:loginForm.password.label')),
  });

  const [disableButton, setDisableButton] = useState(false)
  PubSub.subscribe(Topics.STOP_LOADER, () => setDisableButton(false))

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const intent = searchParams.get('intent');
  const { isLoggedIn, user } = useUserContext();
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      email: user ? user.email : '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (user)
      reset({
        email: user ? user.email : '',
        password: '',
      });
  }, [user, reset]);


  useEffect(() => {
    if (isLoggedIn) {
      const redirectUrl = redirectAfterAuth(siteMetaContext)
      navigate(intent || redirectUrl);
    }
  }, [isLoggedIn])

  const onSubmit = async (data) => {
    setDisableButton(true)
    PubSub.publish(Topics.LOGIN, data)
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Inner>
          <FormControl>
            <TextInput
              name="email"
              placeholder={t('common:registrationForm.email.placeholder')}
              aria-label={t('common:registrationForm.email.label')}
              ref={register}
              fluid
              error={errors.email?.message}
            />
          </FormControl>
          <FormControl>
            <TextInput
              name="password"
              placeholder={t('common:loginForm.password.placeholder')}
              aria-label={t('common:loginForm.password.label')}
              type="password"
              ref={register}
              fluid
              error={errors.password?.message}
            />
          </FormControl>

          <Disclaimer>
            {t('login.signIn.forgotYourPassword')} {' '}
            <Link className="font-bold underline" to="/resetpassword">
            {t('login.signIn.clickToReset')}
            </Link>
            .
          </Disclaimer>

          <FormControl>
            <Button type="submit" fluid disabled={disableButton}>
              {t('common:logIn')}
            </Button>
          </FormControl>

          {errors && errors.form && (
            <ErrorText>{errors.form?.message}</ErrorText>
          )}
        </Inner>
      </Form>
    </Wrapper>
  );
};

export default SigninForm;
