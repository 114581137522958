import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { isMobileOnly } from 'react-device-detect';

import { plans } from '../../constants/plans';
import FlashOnlyIcon from '../../images/flash_drive_only_icon.inline.svg';
import OnlineOnlyIcon from '../../images/online_only_icon.inline.svg';
import Container from '../../layout/Container';
import Ticket from '../../components/Ticket';
import IconList, { PaddingSize } from '../../components/IconList';
import TabButtons from '../../components/TabButtons';
import { useUserContext } from '../../context/UserContext';
import { useSiteMetaContext } from '../../context/SiteMetaContext';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const SalesOptionsContainer = styled(Container)`
  color: white;
  text-align: center;
  padding-top: ${({ theme }) => theme.sizes.lvl2};
`;
const SalesOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    flex-direction: row;
  `};
`;

const SaleOptionWrapper = styled.div<{ $selected: boolean }>`
  flex: 1;
  padding: 0 0 ${({ theme }) => theme.sizes.lvl2} 0;
  :last-child {
    padding-bottom: 0;
  }
  display: ${({ $selected }) => ($selected ? 'block' : 'none')};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: block;
    padding: 0 ${({ theme }) => theme.sizes.lvl2};
    :last-child {
      padding-right: 0;
    }
    :first-child {
      padding-left: 0;
    }
  `}
`;

const Title = styled.h2`
  && {
    margin-bottom: ${({ theme }) => theme.sizes.lvl8};
    font-weight: 400;
  }
`;

const OrangeSpan = styled.span`
  color: ${({ theme }) => theme.colours.primary};
  font-weight: 600;
`;
const BlueSpan = styled.span`
  color: ${({ theme }) => theme.colours.secondary};
  font-weight: 600;
`;

const IconContainer = styled.span`
  display: flex;
  justify-content: center;
`;

const PositionedTabButtons = styled(TabButtons)`
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};

  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: none;
  `}
`;

const StyledIconList = styled(IconList)`
  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    &:last-child {
      border-bottom: none;
    }
  }
`;

interface SalesOptionsSection {
  className?: string;
}

const SalesOptionsSection = ({ className }: SalesOptionsSection) => {
  const {t} = useTranslation();
  const { isPaid, hasPaidForProduct } = useUserContext();
  const { title } = useSiteMetaContext();
  const [selectedTab, setSelectedTab] = useState<string>('both');
  const flashPlan = plans[process.env.GATSBY_REACT_I18N_NAMESPACE].filter((el) => el.id === 'flash')[0];
  const onlineflashPlan = plans[process.env.GATSBY_REACT_I18N_NAMESPACE].filter((el) => el.id === 'onlineflash')[0];
  const onlinePlan = plans[process.env.GATSBY_REACT_I18N_NAMESPACE].filter((el) => el.id === 'online')[0];

  const alwaysIncludedContent = t('sales.salesOptions.commonItems', {returnObjects: true, defaultValue: []});
  const onlineOnlyContent = t('sales.salesOptions.onlineOnly.items', {returnObjects: true, defaultValue: []})
    .map( item => {
      return {...item, content: item.content.replace('{title}', title)}
    });
  const flashOnlyContent = t('sales.salesOptions.flashOnly.items', {returnObjects: true, defaultValue: []}).map( item => {
    return  {...item, content: item.content.replace('{title}', title)}
  });

  const bothContent = t('sales.salesOptions.both.items', {returnObjects: true, defaultValue: []}).map( item => {
    return  {...item, content: item.content.replace('{title}', title)}
  });

  return (
    <SalesOptionsContainer className={className}>
      <Title className="h2" id="salesOptions" dangerouslySetInnerHTML={{__html: t('sales.salesOptions.title', {title})}}>
      </Title>
      <PositionedTabButtons
        value={selectedTab}
        onClick={setSelectedTab}
        tabs={[
          {
            id: 'onlineonly',
            label: (
              <span>
                <strong>{t('sales.salesOptions.onlineOnly.title')}</strong>
                <br />
                {t('sales.salesOptions.only')}
              </span>
            ),
          },
          {
            id: 'flashonly',
            label: (
              <span>
                <strong>{t('sales.salesOptions.flashonly.title')}</strong>
                <br />
                {t('sales.salesOptions.only')}
              </span>
            ),
          },
          {
            id: 'both',
            label: (
              <span>
                <strong>{t('sales.salesOptions.both.title')}</strong>
              </span>
            ),
          },
        ]}
      />
      <SalesOptions>
        <SaleOptionWrapper $selected={selectedTab === 'onlineonly'}>
          <Ticket
            title={
              <strong>
                <IconContainer>
                  <OnlineOnlyIcon />
                </IconContainer>
                <div>
                  <OrangeSpan>{t('sales.salesOptions.onlineOnly.title')}</OrangeSpan> {t('sales.salesOptions.only')}
                </div>
              </strong>
            }
            content={
              <StyledIconList
                showMoreAfterXItems={isMobileOnly ? 6 : undefined}
                paddingSize={PaddingSize.LG}
                items={[
                  ...onlineOnlyContent,
                  ...alwaysIncludedContent,
                ]}
              />
            }
            paid={isPaid && hasPaidForProduct(onlinePlan.name)}
            oldPrice={`$${onlinePlan.oldPrice}`}
            newPrice={`$${onlinePlan.price}`}
            onButtonClick={() => {
                const  checkoutUrl = process.env.GATSBY_CHECKOUT_ONLINE_ONLY
                navigate(checkoutUrl);
            }}
            soldOut={onlinePlan.soldOut}
          />
        </SaleOptionWrapper>
        <SaleOptionWrapper $selected={selectedTab === 'flashonly'}>
          <Ticket
            oldPrice={`$${flashPlan.oldPrice}`}
            newPrice={`$${flashPlan.price}`}
            onButtonClick={() => {
                const  checkoutUrl = process.env.GATSBY_CHECKOUT_FLASH_ONLY
                navigate(checkoutUrl);
            }}
            title={
              <strong>
                <IconContainer>
                  <FlashOnlyIcon />
                </IconContainer>
                <div>
                  <BlueSpan>{t('sales.salesOptions.flashonly.title')}</BlueSpan> {t('sales.salesOptions.only')}
                </div>
              </strong>
            }
            content={
              <StyledIconList
                showMoreAfterXItems={isMobileOnly ? 6 : undefined}
                paddingSize={PaddingSize.LG}
                items={[
                  ...flashOnlyContent,
                  ...alwaysIncludedContent,
                ]}
              />
            }
            paid={isPaid && hasPaidForProduct(flashPlan.name)}
            soldOut={flashPlan.soldOut}
          />
        </SaleOptionWrapper>
        <SaleOptionWrapper $selected={selectedTab === 'both'}>
          <Ticket
            isBestDeal
            oldPrice={`$${onlineflashPlan.oldPrice}`}
            newPrice={`$${onlineflashPlan.price}`}
            onButtonClick={() => {
              const  checkoutUrl = process.env.GATSBY_CHECKOUT_ONLINE_AND_FLASH
              navigate(
                  checkoutUrl
              );
            }}
            title={
              <strong>
                <IconContainer>
                  <OnlineOnlyIcon />
                  <FlashOnlyIcon />
                </IconContainer>
                <div>
                  <OrangeSpan>{t('sales.salesOptions.onlineOnly.title')}</OrangeSpan><br/>+{' '}
                  <BlueSpan>{t('sales.salesOptions.flashonly.title')}</BlueSpan>
                </div>
              </strong>
            }
            paid={isPaid && hasPaidForProduct(onlineflashPlan.name)}
            soldOut={onlineflashPlan.soldOut}
            content={
              <StyledIconList
                showMoreAfterXItems={isMobileOnly ? 6 : undefined}
                paddingSize={PaddingSize.LG}
                items={[
                  ...bothContent,
                  ...alwaysIncludedContent,
                ]}
              />
            }
          />
        </SaleOptionWrapper>
      </SalesOptions>
    </SalesOptionsContainer>
  );
};

export default SalesOptionsSection;
