import React from 'react';
import { graphql } from 'gatsby';

import VideoPage from './video';

const SessionPage = ({ data }) => {
  const video = data?.airtable?.data || {};
  return <VideoPage data={video} freeDate={(video.Day || [])[0]?.data?.Date} />;
};

export const pageQuery = graphql`
  query SessionByName($slug: String!, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    airtable(table: { eq: "Session" }, data: { Slug: { eq: $slug } }) {
      data {
        Name
        Tagline {
          childMarkdownRemark {
            html
          }
        }
        Video
        Gift
        Day {
          data {
            Date
          }
        }
        Transcript
        Audio
        Speaker {
          data {
            WebsiteUri
            Name
            Bio {
              childMarkdownRemark {
                html
              }
            }
            Avatar {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default SessionPage;
