import {PubSub} from "./pubsub"
import Topics from "./pubsub-topics";
import NProgress from "accessible-nprogress"


function handleLoader(key, _data){
    if(key.includes('start')){
        NProgress.start()
    } else {
        NProgress.done()
    }
}

(function(){
    PubSub.subscribe(Topics.START_LOADER, handleLoader)
    PubSub.subscribe(Topics.STOP_LOADER, handleLoader)
})()