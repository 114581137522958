import React from 'react';
import { graphql } from 'gatsby';

import AgendaExpertsSection from '../sections/agendaExperts';
import Seo from '../components/Seo';
import YourHostSection from '../sections/yourHost';
import BuyNowCta from '../sections/buyNowCta';
import Sponsors from '../sections/sponsors';
import RegisteredLayout from '../layout/RegisteredLayout';

import {useTranslation} from 'gatsby-plugin-react-i18next';

const AgendaPage = ({ location }) => {
  const {t} = useTranslation();

  return (
    <RegisteredLayout location={location}>
    <Seo title={t('agenda.meta.title')} />
    <AgendaExpertsSection />
    <YourHostSection />
    <BuyNowCta />
    <Sponsors />
    </RegisteredLayout>
  )
}  

export default AgendaPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;