import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layout/SalesLayout';
import Seo from '../components/Seo';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const NotFoundPage = () => {
  const {t} = useTranslation();

  return (
  <Layout>
    <div className="sm:py-36 py-16 max-w-screen-xl mx-auto text-center px-4">
      <Seo title={t('common:404.title')} />
      <h1 className="sm:text-8xl text-6xl font-bold">404</h1>
      <h2 className="text-2xl mt-2">Page Not Found</h2>
      <p className="mx-auto mt-6 max-w-xl">Oops, the page you're looking for may no longer be available, or doesn't exist. Please return to homepage.</p>
      <div className="mt-10">
        <a className="bg-primary px-10 py-4 rounded font-bold" style={{color: "white"}} href="/">Home</a>
      </div>
    </div>
  </Layout>
  )
}

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
