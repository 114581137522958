import React from 'react';
import styled from 'styled-components';

const BadgeContainer = styled.span`
  background-color: white;
  color: ${({ theme }) => theme.colours.secondary};
  border-radius: 1000px;
  font-weight: 500;
  padding: ${({ theme }) => theme.sizes.lvl2} ${({ theme }) => theme.sizes.lvl3};
`;

const Icon = styled.span`
  vertical-align: bottom;
  margin-right: ${({ theme }) => theme.sizes.lvl1};
`;

const Badge = ({ className, children, icon }) => {
  return (
    <BadgeContainer className={className}>
      {icon && <Icon className="material-icons">{icon}</Icon>}
      {children}
    </BadgeContainer>
  );
};

export default Badge;
