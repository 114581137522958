import React, {createContext, useState, useEffect, useContext} from 'react';
import publicIp from 'public-ip';
import {onSnapshot, query, where, getDoc, setDoc} from 'firebase/firestore';
import {onAuthStateChanged, signOut} from 'firebase/auth';

import {trackRegistration, getIsFacebook} from '../services/affiliate';
import {getFirebase} from '../services/firebase';
import {User} from '../types';
import {useSessionStorageValue} from '@react-hookz/web';
import { hasPaidForProductAndNotRefunded } from '../utils/customer';


const REGISTERED_EMAIL_KEY = 'registeredEmail';
const REGISTERED_FIRSTNAME_KEY = 'registeredFirstName';
const TRACKING_PARAM_KEY = 'trackingParams';

interface UserContextProps {
    user: User;
    firebase: object;
    selectedPlan?: object;
    getFeaturedGiftDownloadLink: Function;
    updateDb: Function;
    register: Function;
    hasPaidForProduct: (productDescription: string) => boolean;
    signOut: Function;
    signUp: Function;
    resetPassword: Function;
    setPlan: Function;
    createPaypalOrder: Function;
    confirmPaypalOrder: Function;
    createPaidCustomer: Function;
    deletePaidCustomer: Function;
    exportCustomers: Function;
    isPaid: Boolean;
    isRegistered: Boolean;
    isLoggedIn: Boolean;
    isAdmin: Boolean;
}

const defaultContext = {
    user: {},
    updateDb: () => {
    },
    register: () => {
    },
    getFeaturedGiftDownloadLink: () => {
    },
    hasPaidForProduct: () => false,
    isPaid: false,
    isRegistered: false,
    isLoggedIn: false,
    isAdmin: false,
    signOut: () => {
    },
    signUp: () => {
    },
    resetPassword: () => {
    },
    firebase: {},
    selectedPlan: null,
    setPlan: () => {
    },
    createPaypalOrder: () => {
    },
    confirmPaypalOrder: () => {
    },
    createPaidCustomer: () => {
    },
    deletePaidCustomer: () => {
    },
    exportCustomers: () => {
    },
};
export const UserContext = createContext<UserContextProps>(defaultContext);

export const UserContextProvider = ({children}) => {
    const firebase = getFirebase();

    const [user, setUser] = useState<User>({});
    const [paypalPaid, setPaypalPaid] = useState([]);
    const [stripePaid, setStripePaid] = useState([]);
    const [selectedPlan, setPlan] = useState(null);
    const [isPaid, setIsPaid] = useSessionStorageValue<boolean>('paid', false, {initializeWithStorageValue: false});

    const getClientIp = () =>
        publicIp.v4({
            fallbackUrls: ['https://ifconfig.co/ip'],
        });

    useEffect(() => {
        const registeredEmail = localStorage.getItem(REGISTERED_EMAIL_KEY);
        if (registeredEmail) {
            const registrationRef = firebase.registration(registeredEmail)
            getDoc(registrationRef)
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const user = snapshot.data();
                        console.log('setting user', user)
                        setUser(user);
                    } else {
                        console.error('No user reg data found for email', registeredEmail);
                        setUser(undefined);
                    }
                });
        }
    }, [firebase]);

    useEffect(() => {
        const unregisterAuthObserver = onAuthStateChanged(firebase.auth,
            async (authUser) => {
                if (authUser) {
                    const userBase = {
                        uid: authUser.uid,
                        email: authUser.email,
                        emailVerified: authUser.emailVerified,
                        providerData: authUser.providerData,
                        paid: isPaid,
                    };

                    const newSignup = firebase.registration(authUser.email)
                    onSnapshot(newSignup, (snapshot) => {
                        if (snapshot.exists()) {
                            const registrationData = snapshot.data();
                            setUser((oldState) => ({
                                ...userBase,
                                ...oldState,
                                ...registrationData,
                            }));
                        }
                    });

                    const customerAdded = firebase.customer(authUser.uid)
                    onSnapshot(customerAdded, (snapshot) => {
                        if (snapshot.exists()) {
                            const customerData = snapshot.data()
                            setUser((oldState) => ({
                                ...userBase,
                                ...oldState,
                                ...customerData,
                            }));

                            setIsPaid(hasPaidForProductAndNotRefunded(customerData));
                        }
                    });

                    const successfulStripePayments = query(firebase.customerStripePayments(authUser.uid), where('status', '==', 'succeeded'))
                    onSnapshot(successfulStripePayments,
                        (snapshot) => {
                            if (snapshot.empty) {
                                // skip
                            } else {
                                const paymentsPaid = [...stripePaid];
                                snapshot.forEach((change) => {
                                    const payment = change.data()
                                    if (payment.status === 'succeeded' && payment.description) {
                                        paymentsPaid.push(payment.description);
                                    }
                                })
                                setStripePaid(paymentsPaid);
                            }
                        });

                    const successfulPaypalPayments = query(firebase
                        .customerPaypalPayments(authUser.uid), where('status', '==', 'succeeded'))
                    onSnapshot(successfulPaypalPayments,
                        (snapshot) => {
                            if (snapshot.empty) {
                                // skip
                            } else {
                                const paymentsPaid = [...paypalPaid];
                                snapshot.forEach((change) => {
                                    const payment = change.data()
                                    if (payment.status === 'succeeded' && payment.description) {
                                        paymentsPaid.push(payment.description);
                                    }
                                })
                                
                                setPaypalPaid(paymentsPaid);
                            }
                        });

                    setUser(userBase);
                }
            },
            () => {
                console.log('Signed Out');
                setUser(undefined);
                setIsPaid(false);
                setPaypalPaid([]);
                setStripePaid([]);
            }
        );
        return () => unregisterAuthObserver();
    }, []);

    const getFeaturedGiftDownloadLink = () =>
        firebase.getFeaturedGiftDownloadLink();

    const updateUserDb = async (payload, _user = user) => {
        try {
            await firebase.customer(_user.uid).set(
                {
                    ...payload,
                },
                {merge: true}
            );
        } catch (e) {
            console.error('Error updating user db', e);
        }
    };

    const registerUserDb = async (payload) => {
        try {
            if (user && user.email) {
                firebase.doSignOut();
            }
            const ip = await getClientIp();
            if (ip) {
                const data = {
                    ...payload,
                    ip,
                    userAgent: window.navigator.userAgent,
                    isFb: getIsFacebook(),
                    trackingParams: JSON.parse(localStorage.getItem(TRACKING_PARAM_KEY)) || {}
                };

                const registrationRef = firebase.registration(payload.email)
                await setDoc(registrationRef, data, {merge: true});

                localStorage.setItem(REGISTERED_EMAIL_KEY, payload.email);
                localStorage.setItem(REGISTERED_FIRSTNAME_KEY, payload.firstName);
                await trackRegistration(payload.email, payload.firstName);
                setUser(payload);
            } else {
                throw Error('Error retrieving IP of user');
            }
        } catch (e) {
            console.error('Error registering user', e);
        }
    };

    const resetPassword = ({email, continueUrl}) =>
        firebase.doPasswordReset(email, {url: continueUrl});

    const hasPaidForProduct = (productDescription: string) => {
        const paidWithInfusionsoft = (user && user.paid)

        const paidWithPaypal = !!paypalPaid.find((paymentDescription) =>
            paymentDescription
                .toLowerCase()
                .includes(productDescription.toLowerCase())
        )

        const paidWithStripe = !!stripePaid.find((paymentDescription) =>
            paymentDescription
                .toLowerCase()
                .includes(productDescription.toLowerCase())
        );

        return (paidWithInfusionsoft || paidWithPaypal || paidWithStripe)
    }


    const createPaypalOrder = () => {
        const createOrderMethod = firebase.paypalCreateOrder();
        return createOrderMethod({
            product_id: selectedPlan.id,
            shipping:
                selectedPlan.requiresAddress && user.address
                    ? {
                        address: {
                            address_line_1: user.address.line1,
                            address_line_2: user.address.line2,
                            country_code: user.address.country,
                            admin_area_1: user.address.region,
                            admin_area_2: user.address.city,
                            postal_code: user.address.zip,
                        },
                    }
                    : undefined,
        }).then((response) => response.data.id);
    };

    const confirmPaypalOrder = (orderId) => {
        const confirmOrderMethod = firebase.paypalConfirmOrder();
        return confirmOrderMethod({orderId});
    };

    const createPaidCustomer = (payload) => {
        const createPaidCustomerMethod = firebase.createPaidCustomer();
        return createPaidCustomerMethod(payload);
    };

    const deletePaidCustomer = (payload) => {
        const deletePaidCustomerMethod = firebase.deletePaidCustomer();
        return deletePaidCustomerMethod(payload);
    };

    const exportCustomers = (payload) => {
        const exportCustomersMethod = firebase.exportCustomers();
        return exportCustomersMethod(payload);
    };

    const signOut = (e) => {
        if(e) e.preventDefault()

        firebase.doSignOut().then(() => {
            window.location.reload()
        })
    }

    return (
        <UserContext.Provider
            value={{
                user,
                updateDb: updateUserDb,
                register: registerUserDb,
                getFeaturedGiftDownloadLink,
                hasPaidForProduct,
                isPaid,
                isRegistered: !!(user && user.email),
                isLoggedIn: !!(user && user.uid),
                isAdmin: user && user.admin,
                signOut,
                signUp: firebase.doCreateUserWithEmailAndPassword,
                resetPassword,
                firebase,
                selectedPlan,
                setPlan,
                createPaypalOrder,
                confirmPaypalOrder,
                createPaidCustomer,
                deletePaidCustomer,
                exportCustomers,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (typeof window === 'undefined') {
        return defaultContext;
    }

    if (context === undefined) {
        throw new Error('useUserContext must be used within a UserContextProvider');
    }
    return context;
};

export default UserContextProvider;
