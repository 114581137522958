import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';


import Seo from '../components/Seo';
import RegisteredLayout from '../layout/RegisteredLayout';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const Title = styled.h3`
  text-align: center;
  padding: ${({ theme }) => theme.sizes.lvl4};
  font-family: ${({ theme }) => theme.font.families.body} !important;
`;

const ComingSoonPage = () => {
  const {t} = useTranslation();

  return (
    <RegisteredLayout>
      <Seo title={t('comingSoon.meta.title')} />
      <Title dangerouslySetInnerHTML={{__html: t('comingSoon.msg')}}>
      </Title>
    </RegisteredLayout>
  )
};

export default ComingSoonPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;