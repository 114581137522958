import React from 'react';
import styled from 'styled-components';
import {graphql} from 'gatsby';

import GiftSvgPath from '../images/gift.svg';
import Layout from '../layout/Layout';
import Seo from '../components/Seo';
import ScrollTarget from '../components/ScrollTarget';
import TooltipSvgInner from '../components/TooltipSvgInner';
import LandingIntroSection from '../sections/landing/landingIntro';
import RegistrationSection from '../sections/registration';
import YourHostSection from '../sections/yourHost';
import LandingGifts from '../sections/landing/landingGifts';
import LandingGiftsMoreDetailsSection from '../sections/landing/landingGiftsMoreDetails';
import WhatIsSuperConferenceSection from '../sections/whatIsSuperConference';
import ConferenceContentSection from '../sections/conferenceContent';
import HomepageExpertsSection from '../sections/homepageExperts';
import SponsorsSection from '../sections/sponsors';
import LandingTrailerSection from '../sections/landing/landingTrailer';
import LandingAdditionalContentSection from '../sections/landing/landingAdditionalContent';
import SpeakersCarouselSection from '../sections/speakersCarousel';

import {useSiteMetaContext} from '../context/SiteMetaContext';

import {useTranslation} from 'gatsby-plugin-react-i18next';
import {isBrowser} from "../utils";

const RegistrationMobileOnly = styled(RegistrationSection)`
  ${({theme}) => theme.breakpoint('tabletLandscape')`
  display: none;
  `};
`;

const RegistrationDesktopOnly = styled(RegistrationSection)`
  display: none;
  ${({theme}) => theme.breakpoint('tabletLandscape')`
    display: block;
  `};
`;

const PaperBackground = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.colours.backgroundPaper};
`;

const IndexPage = ({data, location}) => {
    const {t} = useTranslation();

    const {landingBg, landingBgMobile, speakers: rawSpeakers} = data

    const speakers =
        rawSpeakers?.edges
            ?.map((edge) => edge.node)
            .map((node) => ({
                id: node.id,
                name: node.data.Name,
                image: node.data.Avatar.localFiles[0],
                tagline: node.data.Session[0].data.Name,
                slug: node.data.Session[0].data.Slug,
            })) || [];

    const {conferenceDateString, title} = useSiteMetaContext();
    // Affiliate Id is returned from infusionsoft's redirect. We store it here so we can post it when creating firebase registration later.
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.affiliate) {
        localStorage.setItem('superconferenceAffiliate', params.affiliate);
    }

    const registrationTooltipInner = (
        <TooltipSvgInner
            content={
                <>
                    { t('index.registrationTooltip.part1') }
                    <br/>
                    { t('index.registrationTooltip.part2') }
                </>
            }
        >
            <GiftSvgPath className="w-8 h-8"/>
        </TooltipSvgInner>
    );
    const registrationTitle = <span>{t('index.registrationTooltip.part1')} + { t('index.registrationTooltip.part2') }</span>;
    const registrationSubtitle = (
        <div>
            {t('index.registration.subtitle')} {title} <br/>
            {conferenceDateString}
        </div>
    );

    return (
        <Layout>
            <Seo title={t('index.meta.title')}/>

            <LandingIntroSection
                title={
                    <div className="hidden lg:block">
                        { t('index.hero.title') }
                    </div>
                }
                subtitle={
                    <div className="hidden lg:block">
                        { t('index.hero.body') }
                    </div>
                }
                landingBg={landingBg}
                landingBgMobile={landingBgMobile}
                hideVideo={true}
            />

            <RegistrationDesktopOnly
                inline
                tooltipInner={registrationTooltipInner}
            />

            <RegistrationMobileOnly
                title={<strong>{t('index.hero.title')}</strong>}
                subtitle={registrationSubtitle}
                tooltipInner={registrationTooltipInner}
            />

            <LandingTrailerSection/>

            <ScrollTarget id="signup"/>

            <RegistrationSection
                inline
                title={registrationTitle}
                subtitle={registrationSubtitle}
                tooltipInner={registrationTooltipInner}
            />

            <LandingGifts/>

            <RegistrationSection
                inline
                title={<span>{t('index.instantAccess.title')}</span>}
                subtitle={registrationSubtitle}
                tooltipInner={registrationTooltipInner}
            />

            <PaperBackground>
                <SpeakersCarouselSection
                    speakers={speakers}
                    title={
                        <div dangerouslySetInnerHTML={{__html: t('index.speakersCarousel.title')}}></div>
                    }
                />
            </PaperBackground>

            <ConferenceContentSection/>

            <WhatIsSuperConferenceSection/>

            <ScrollTarget id="conferenceexperts"/>
            <PaperBackground>
                <HomepageExpertsSection/>
            </PaperBackground>

            <LandingAdditionalContentSection/>

            <LandingGiftsMoreDetailsSection/>

            <RegistrationSection
                inline
                title={registrationTitle}
                subtitle={registrationSubtitle}
                tooltipInner={registrationTooltipInner}
            />

            <YourHostSection
            showHeader
            style={{background:"none"}}/>

            <SponsorsSection/>
        </Layout>
    );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    landingBg: file(relativePath: { eq: "landing_bg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    landingBgMobile: file(relativePath: { eq: "landing_bg_mobile.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    speakers: allAirtable(
      filter: {table: {eq: "Speaker"}, data: {Carousel: {eq: true}, Session: {elemMatch: {data: {Slug: {ne: null}}}}}}
      sort: {fields: data___CarouselOrder, order: ASC}
    ) {
      edges {
        node {
          id
          data {
            Name
            Avatar {
              localFiles {
                childImageSharp {
                  gatsbyImageData(width: 320)
                }
              }
            }
            Session {
              data {
                Slug
                Name
              }
            }
          }
        }
      }
    }

  }
`;
