import React from 'react';
import styled from 'styled-components';

import SessionCard from './SessionCard';
import Carousel from './Carousel';
import { Session } from '../types';

const SessionListItemsDesktop = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${({ theme }) => theme.sizes.lvl3};
    grid-row-gap: ${({ theme }) => theme.sizes.lvl3};
  `};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
  grid-template-columns: repeat(6, 1fr);
  `};
`;

const MobileOnly = styled.div`
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
  display: none;
  `};
`;

// Setting the span to 2 and doubling the columns centers is a trick to center the last item in the last row in the grid
const StyledSessionCard = styled(SessionCard)`
  grid-column: span 2;
  &:nth-last-child(1):nth-child(odd) {
    grid-column: 2 / span 2;
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      grid-column: 3 / span 2;
    `};
  }
`;

interface SessionListProps {
  sessions: Session[];
}

const SessionList = ({ sessions }: SessionListProps) => {
  const sessionElements = sessions.map((session, index) => {
    return <StyledSessionCard {...session} index={index + 1} />;
  });

  return (
    <>
      <MobileOnly>
        <Carousel items={sessionElements} />
      </MobileOnly>
      <SessionListItemsDesktop>{sessionElements}</SessionListItemsDesktop>
    </>
  );
};

export { SessionList };
export default SessionList;
