import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import SalesLayout from '../layout/SalesLayout';
import { useUserContext } from '../context/UserContext';
import Seo from '../components/Seo';
import Button from '../components/Button';
import Container from '../layout/Container';
import CreatePaidCustomerForm from '../components/administrator/createPaidCustomerForm';
import DeletePaidCustomerForm from '../components/administrator/deletePaidCustomerForm';
import ExportCustomersAsCsvForm from '../components/administrator/exportCustomersAsCsvForm';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const PositionedButton = styled(Button)`
  margin-top: ${({ theme }) => theme.sizes.lvl2};
`;

const AdminPage = ({ location }) => {
  const { isAdmin, isLoggedIn, signOut } = useUserContext();
  const {t} = useTranslation();

  let inner;

  if (!isAdmin) {
    inner = (
      <>
        <p>{t('admin.nonAdminMsg')}</p>
        <Link to="/">{t('common:homepage')}</Link>
        <br />
        {isLoggedIn ? (
          <PositionedButton onClick={signOut}>{t('common:logout')}</PositionedButton>
        ) : (
          <Link to="/login">{t('common:login')}</Link>
        )}
      </>
    );
  } else {
    inner = (
      <>
        <h1>{t('admin.adminControls')}</h1>
        <CreatePaidCustomerForm />
        <DeletePaidCustomerForm />
        <ExportCustomersAsCsvForm />
      </>
    );
  }

  return (
    <SalesLayout location={location}>
      <Seo title={t('admin.meta.title')} />
      <Container>{inner}</Container>
    </SalesLayout>
  );
};

export default AdminPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;