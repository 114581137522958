import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Session } from '../types';

interface SessionCardProps extends Session {
  className?: string;
  index?: number;
}

const Card = styled.article`
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  background-color: ${({ theme }) => theme.colours.secondary};
  color: ${({ theme }) => theme.colours.white};
`;

const Inner = styled.div`
  padding: ${({ theme }) => theme.sizes.lvl4};
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-top-left-radius: ${({ theme }) => theme.sizes.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.sizes.borderRadius};
  height: 16rem;
  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const Title = styled.h1`
  &:first-line {
    font-weight: 700;
    font-size: ${({ theme }) => theme.font.sizes.xxxl};
    max-width: 10rem;
  }
  font-size: ${({ theme }) => theme.font.sizes.lg};
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colours.white};
  text-decoration: none;
  &:hover {
    h1 {
      text-decoration: underline;
    }
  }
`;

const Teaser = styled.div`
  font-size: ${({ theme }) => theme.font.sizes.sm};
  color: rgba(255, 255, 255, 0.75);
`;

const Index = styled.div`
  position: absolute;
  height: ${({ theme }) => theme.sizes.lvl6};
  width: ${({ theme }) => theme.sizes.lvl6};
  top: ${({ theme }) => theme.sizes.lvl2};
  left: ${({ theme }) => theme.sizes.lvl2};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colours.secondary};
  color: ${({ theme }) => theme.colours.white};
  font-weight: 700;
`;

const SessionCard = ({
  name,
  teaser,
  image,
  index,
  slug,
  className,
}: SessionCardProps) => {
  return (
    <Card className={className}>
      <StyledLink to={`/session/${slug}`}>
        {image && (
          <ImageWrapper>
            <GatsbyImage
              alt={`${name} image`}
              image={getImage(image)}
              imgStyle={{ height: '100%' }}
            />
            {index !== undefined && <Index>{index}</Index>}
          </ImageWrapper>
        )}
        <Inner>
          {name && <Title className="h3">{name}</Title>}
          {teaser && <Teaser>{teaser}</Teaser>}
        </Inner>
      </StyledLink>
    </Card>
  );
};

export { SessionCard };
export default SessionCard;
