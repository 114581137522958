import React from 'react';
import PropTypes from 'prop-types';
import { useSiteMetaContext } from '../context/SiteMetaContext';

import Layout from './Layout';
import Header from '../components/SalesHeader';

const SalesLayout = ({ children }) => {
  const { title } = useSiteMetaContext();
  return (
    <Layout>
      <Header siteTitle={title}>
        Get <span>LIFETIME</span> access to the <br /> <span>{title}</span>{' '}
        today!
      </Header>
      <main>{children}</main>
    </Layout>
  );
};

SalesLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

SalesLayout.defaultProps = {
  className: '',
  children: undefined,
};

export default SalesLayout;
