import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import GiftSvgPath from '../images/gift.svg';
import { useSiteMetaContext } from '../context/SiteMetaContext';
import Layout from '../layout/Layout';
import Seo from '../components/Seo';
import ScrollTarget from '../components/ScrollTarget';
import TooltipSvgInner from '../components/TooltipSvgInner';
import LandingIntroSection from '../sections/landing/landingIntro';
import RegistrationSection from '../sections/registration';
import StickyRegistration from '../sections/stickyRegistration';
import { MeditationSessionsSection } from '../sections/meditationSessions';
import HomepageAltExpertsSection from '../sections/homepageAltExperts';
import YourHostSection from '../sections/yourHost';
import SponsorsSection from '../sections/sponsors';
import SpeakersCarouselSection from '../sections/speakersCarousel';
import LandingAltTrailerSection from '../sections/landing/landingAltTrailer';
import LandingJoinAlexSection from '../sections/landing/landingJoinAlex';

const RegistrationMobileOnly = styled(RegistrationSection)`
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: none;
  `};
`;

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.white};
`;

const IndexPage = ({data, location }) => {
  const {t} = useTranslation();

  const registrationButtonLabel =  t('indexAlt.registrationButtonLabel');

  const { title } = useSiteMetaContext();
  const { allAirtable, landingBg, landingBgMobile } = data;
  // Affiliate Id is returned from infusionsoft's redirect. We store it here so we can post it when creating firebase registration later.
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.affiliate) {
    localStorage.setItem('superconferenceAffiliate', params.affiliate);
  }

  const speakers =
    allAirtable?.edges
      ?.map((edge) => edge?.node)
      .filter(({ data }) => !!data.Speaker)
      .sort(
        (speakerA, speakerB) =>
          speakerA.data.ExpertOrder - speakerB.data.ExpertOrder
      )
      .map(({ data }) => ({
        id: data.Speaker[0].id,
        name: data.Speaker[0].data.Name,
        tagline: data.Name,
        image: data.Speaker[0].data.Avatar.localFiles[0],
        slug: data.Slug,
        content: data.Content,
        category: data.Category,
      })) || [];

  const registrationTooltipInner = (
    <TooltipSvgInner
      svgPath={GiftSvgPath}
      content={
        <>
          {t('indexAlt.registrationTooltip.part1')}
          <br />
          {t('indexAlt.registrationTooltip.part2')}
        </>
      }
    />
  );

  return (
    <Layout>
      <Seo title={t('indexAlt.meta.title')} />

      <LandingIntroSection
        title={t('indexAlt.hero.title')}
        subtitle={
          <div dangerouslySetInnerHTML={{__html: t('indexAlt.hero.body')}}>
          </div>
        }
        buttonLabel={registrationButtonLabel}
        tooltipInner={registrationTooltipInner}
        landingBg={landingBg}
        landingBgMobile={landingBgMobile}
        hideVideo
      />

      <RegistrationMobileOnly primaryButtonLabel={registrationButtonLabel} />

      <LandingAltTrailerSection />

      <LandingJoinAlexSection />

      <MeditationSessionsSection />

      <ScrollTarget id="signup" />

      <StickyRegistration
        title={<span>{t('indexAlt.stickyRegistration.title')}</span>}
        subtitle={t('indexAlt.stickyRegistration.subtitle')}
        primaryButtonLabel={registrationButtonLabel}
        tooltipInner={registrationTooltipInner}
      />

      <Background>
        <SpeakersCarouselSection
          speakers={speakers}
          svgPath={GiftSvgPath}
          preTitle={
            <div dangerouslySetInnerHTML={{__html: t('indexAlt.speakersCarousel.preTitle')}}></div>
          }
          title={
            <div>
              {t('indexAlt.speakersCarousel.title')}
              <br />
              {title}
            </div>
          }
          subtitle={
            <div dangerouslySetInnerHTML={{__html: t('indexAlt.speakersCarousel.subtitle')}}>
            </div>
          }
        />
        <HomepageAltExpertsSection speakers={speakers} />
        <YourHostSection showHeader />
      </Background>

      <SponsorsSection />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    landingBg: file(relativePath: { eq: "landing_bg_meditation.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    landingBgMobile: file(
      relativePath: { eq: "landing_bg_meditation_mobile.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allAirtable(
      filter: {
        table: { eq: "Session" }
        data: {
          BonusSession: { ne: true }
          Speaker: {
            elemMatch: {
              data: { MainHost: { ne: true }, Host: { ne: true }, Avatar: {id: {ne: null}} }
            }
          }
        }
      }
    ) {
      edges {
        node {
          data {
            Name
            DateOrder
            BonusSession
            Content {
              childMarkdownRemark {
                html
              }
            }
            Speaker {
              id
              data {
                Name
                WebsiteUri
                ExpertOrder
                Host
                MainHost
                Avatar {
                  localFiles {
                    childImageSharp {
                      gatsbyImageData(width: 400)
                    }
                  }
                }
              }
            }
          }
          id
        }
      }
    }
  }
`;