import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import Container from '../layout/Container';
import SessionList from '../components/SessionList';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const Title = styled.h1`
  font-weight: 700;
  text-align: center;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    margin-top: ${({ theme }) => theme.sizes.lvl6};
    margin-bottom: ${({ theme }) => theme.sizes.lvl8};
  `};
`;

const MeditationSessionsSection = () => {
  const {t} = useTranslation();

  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: {
          table: { eq: "Session" }
          data: { Category: { eq: "Meditation" } }
        }
        sort: { fields: data___Day___data___Date, order: ASC }
      ) {
        edges {
          node {
            id
            data {
              Slug
              Name
              Tagline {
                childMarkdownRemark {
                  html
                }
              }
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 720)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const sessions = allAirtable?.edges?.map((edge) => ({
    name: edge?.node?.data?.Name,
    teaser: (
      <p
        dangerouslySetInnerHTML={{
          __html: edge?.node?.data?.Tagline?.childMarkdownRemark?.html,
        }}
      />
    ),
    image: edge?.node?.data?.Image?.localFiles[0],
    slug: edge?.node?.data?.Slug,
  }));

  return (
    <Background>
      <Container>
        <Title className="h2">{t('indexAlt.meditationSection.title')}</Title>
        <SessionList sessions={sessions} />
      </Container>
    </Background>
  );
};

export { MeditationSessionsSection };
export default MeditationSessionsSection;
