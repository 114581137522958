import React from 'react';
import styled from 'styled-components';
import Container from '../../layout/Container';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const Inner = styled(Container)`
  position: relative;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    text-align: center;
  `}
`;

const Paragraph = styled.p`
  && {
    line-height: 1.7;
    margin: 0;
    max-width: none;
    font-size: ${({ theme }) => theme.font.sizes.lg};
    font-weight: 400;
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      line-height: 2;
      font-size: ${({ theme }) => theme.font.sizes.xxl};
    `}
    u {
      text-underline-position: under;
    }
    strong {
      font-weight: 700;
    }
  }
`;

const LandingAltTrailerSection = () => {
  const {t} = useTranslation();

  return (
    <Background>
      <Inner>
        <Paragraph dangerouslySetInnerHTML={{__html: t('indexAlt.trailerSection.description')}}>
        </Paragraph>
      </Inner>
    </Background>
  );
};

export default LandingAltTrailerSection;
