import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { format } from 'date-fns';

import { ColourLevel } from '../types';
import Button from './Button';
import { useSiteMetaContext } from '../context/SiteMetaContext';

const TicketWrap = styled.div<{ $isBestDeal: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  background-color: ${({ theme }) => theme.colours.white};
  color: ${({ theme }) => theme.colours.text};
  padding: ${({ theme }) => `${theme.sizes.lvl6} ${theme.sizes.lvl4}`};
  box-shadow: 0px 0px 0px 5px
    ${({ theme, $isBestDeal }) =>
      $isBestDeal ? theme.colours.secondaryDark : 'transparent'};
`;

const InnerWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  position: relative;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`;

const Title = styled.h2<{ $isBestDeal: boolean }>`
  height: ${({ theme }) => theme.sizes.lvl14};
  margin: 0 auto;
  && {
    font-weight: ${({ $isBestDeal }) => ($isBestDeal ? '700' : '400')};
    font-size: ${({ theme }) => theme.font.sizes.xl};

    ${({ theme }) => theme.breakpoint('desktop')`
      font-size: ${theme.font.sizes.xxl};
    `}

    span {
      text-transform: none;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  background-color: rgba(255, 255, 255, 0.4);
  cursor: not-allowed;
`;

const PricesWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const OldPrice = styled.h1`
  && {
    font-weight: 400;
    color: ${({ theme }) => theme.colours.textDisabled};
    padding-right: ${({ theme }) => theme.sizes.lvl1};
    text-decoration: line-through;
    margin: 0 0 ${({ theme }) => theme.sizes.lvl0};

    ${({ theme }) => theme.breakpoint('phoneLarge')`
      padding: 0px ${theme.sizes.lvl2} 0px 0px;
    `}
  }
`;

const NewPrice = styled.h1<{ $isBestDeal: boolean }>`
  && {
    color: ${({ theme, $isBestDeal }) =>
      $isBestDeal ? theme.colours.primary : theme.colours.secondary};
    margin: 0 0 ${({ theme }) => theme.sizes.lvl0};
  }
`;

const BestDeal = styled.strong`
  text-align: center;
  margin: ${({ theme }) => theme.sizes.lvl2} auto 0;
  font-size: ${({ theme }) => theme.font.sizes.sm};
  color: ${({ theme }) => theme.colours.primary};
  text-transform: uppercase;
`;

const Content = styled.div`
  text-align: center;
  line-height: 1.2rem;
  margin-bottom: ${({ theme }) => theme.sizes.lvl3};
`;

const Disclaimer = styled.span`
  color: ${({ theme }) => theme.colours.textDisabled};
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  font-size: ${({ theme }) => theme.font.sizes.xs};
`;

interface TicketProps {
  className?: string;
  title?: ReactNode;
  isBestDeal?: boolean;
  content?: ReactNode;
  oldPrice: string;
  newPrice: string;
  onButtonClick: Function;
  paid?: boolean;
  soldOut?: boolean;
}

const Ticket = ({
  className,
  title,
  isBestDeal,
  content,
  newPrice,
  oldPrice,
  onButtonClick,
  paid,
  soldOut,
}: TicketProps) => {
  const { hasConferenceStarted, conferenceStartTime } = useSiteMetaContext();
  const conferenceAvailableDate = conferenceStartTime
    ? format(conferenceStartTime, 'MMMM do yyyy')
    : '';
  return (
    <TicketWrap className={className} $isBestDeal={isBestDeal}>
      {title && <Title $isBestDeal={isBestDeal}>{title}</Title>}
      <InnerWrap>
        {content && <Content>{content}</Content>}
        <PricesWrap>
          <OldPrice>{oldPrice}</OldPrice>
          <NewPrice $isBestDeal={isBestDeal}>{newPrice}</NewPrice>
        </PricesWrap>
        <Disclaimer>United States Dollar</Disclaimer>

        <Button
          onClick={onButtonClick}
          disabled={paid || soldOut}
          colourLevel={isBestDeal ? ColourLevel.PRIMARY : ColourLevel.SECONDARY}
          fluid
        >
          {soldOut ? 'Sold Out' : paid ? 'Purchased' : 'Buy Now'}
        </Button>
        {paid && (
          <Disclaimer>
            Go to the <Link to="/agenda">agenda</Link> to access content.
            {hasConferenceStarted ? (
              ''
            ) : (
              <p>Available from {conferenceAvailableDate}.</p>
            )}
          </Disclaimer>
        )}
        {isBestDeal && <BestDeal>This is the best deal</BestDeal>}
      </InnerWrap>
      {soldOut && <Overlay />}
    </TicketWrap>
  );
};

export default Ticket;
