import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import Container from '../layout/Container';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const Inner = styled(Container)`
  padding-bottom: 0;
`;

const Title = styled.p`
  && {
    text-align: center;
    line-height: 1.7;
    margin: 0 auto ${({ theme }) => theme.sizes.lvl6};
    max-width: 59rem;
    font-size: ${({ theme }) => theme.font.sizes.lg};
    font-weight: 400;
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      line-height: 1.4;
      font-size: ${({ theme }) => theme.font.sizes.xxxl};
    `}
    u {
      text-underline-position: under;
    }
    strong {
      font-weight: 700;
    }
  }
`;
const ImageContainer = styled.div`
  z-index: 1;
  padding: 0 ${({ theme }) => theme.sizes.lvl4};
  max-width: ${({ theme }) => theme.sizes.sizeByFactor(48)};
`;

const Columns = styled.div`
  align-items: center;
`;
const Content = styled.ul`
  flex: 1;
  li {
    color: rgba(0, 0, 0, 0.7);
    position: relative;
    list-style-type: none;
    padding-left: ${({ theme }) => theme.sizes.lvl14};
    margin-bottom: ${({ theme }) => theme.sizes.lvl8};
    &:before {
      content: 'question_mark';
      font-family: 'Material Icons';
      font-size: 2rem;
      width: ${({ theme }) => theme.sizes.lvl8};
      height: ${({ theme }) => theme.sizes.lvl8};
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${({ theme }) => theme.colours.tertiary};
      color: ${({ theme }) => theme.colours.white};
    }
  }
`;

const FeaturedGiftSection = () => {
  const {t} = useTranslation();

  return (
    <Background>
      <Inner>
        <Title dangerouslySetInnerHTML={{__html: t('indexFeaturedGift.featuredGiftSection.title')}}>
        </Title>
        <Columns className={'block md:flex'}>
        <ImageContainer className={'block md:hidden mx-auto w-2/3'}>
            <StaticImage
              alt="Featured gift image"
              src="../images/featuredgift_2.png"
            />
          </ImageContainer>
          <Content>
            <li dangerouslySetInnerHTML={{__html: t('indexFeaturedGift.featuredGiftSection.point1')}}>
            </li>
            <li dangerouslySetInnerHTML={{__html: t('indexFeaturedGift.featuredGiftSection.point2')}}>
            </li>
          </Content>
          <ImageContainer className={'hidden md:block -mb-16'}>
            <StaticImage
              alt="Featured gift image"
              src="../images/featuredgift_2.png"
            />
          </ImageContainer>
          <Content>
          <li dangerouslySetInnerHTML={{__html: t('indexFeaturedGift.featuredGiftSection.point3')}}>
            </li>
            <li dangerouslySetInnerHTML={{__html: t('indexFeaturedGift.featuredGiftSection.point4')}}>  
            </li>
          </Content>
        </Columns>
      </Inner>
    </Background>
  );
};

export default FeaturedGiftSection;
