import React, { createContext, ReactNode, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { format, isPast } from 'date-fns';
import { getIsFacebook, getIsGoogle } from '../services/affiliate';
import { formatConferenceDateString } from '../utils/dates';

interface SiteMetaContextProps {
  title: string;
  uri: string;
  isFb: boolean;
  conferenceStartTime: Date;
  conferenceEndTime: Date;
  replayStartTime?: Date;
  purchaseStartTimeFormatted: string;
  hasPurchaseStarted: boolean;
  hasConferenceStarted: boolean;
  hasConferenceEnded: boolean;
  hasReplayStarted?: boolean;
  hasReplayEnded?: boolean;
  replayWeekendEnabled: boolean;
  isConferenceLive: boolean;
  conferenceDateString: string;
  conferenceDateStringWithDays: string;
  infusionsoftRegistrationFormId: string;
  landingVideoLoop: string;
  landingVideo: string;
  salesVideo: string;
}

const SiteMetaContext = createContext<SiteMetaContextProps>({
  title: '',
  uri: '',
  isFb: false,
  conferenceStartTime: undefined,
  conferenceEndTime: undefined,
  replayStartTime: undefined,
  purchaseStartTimeFormatted: undefined,
  hasPurchaseStarted: undefined,
  hasConferenceStarted: undefined,
  hasConferenceEnded: undefined,
  hasReplayStarted: undefined,
  hasReplayEnded: undefined,
  replayWeekendEnabled: false,
  isConferenceLive: undefined,
  conferenceDateString: undefined,
  conferenceDateStringWithDays: undefined,
  infusionsoftRegistrationFormId: '',
  landingVideoLoop: '',
  landingVideo: '',
  salesVideo: '',
});

interface SiteMetaContextProviderProps {
  children: ReactNode;
}

const SiteMetaContextProvider = ({
  children,
}: SiteMetaContextProviderProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          uri
          conferenceInformation {
            infusionsoftRegistrationFormId
            infusionsoftFacebookRegistrationFormId
            infusionsoftGoogleRegistrationFormId
            infusionsoftFeaturedGiftRegistrationFormId
            landingVideoLoop
            landingVideo
            salesVideo
            purchaseStarttime {
              year
              month
              day
              hour
            }
            startTime {
              year
              month
              day
              hour
            }
            endTime {
              year
              month
              day
              hour
            }
            replayStarttime {
              year
              month
              day
              hour
            }
            replayEndtime {
              year
              month
              day
              hour
            }
            replayWeekendEnabled
          }
        }
      }
    }
  `);

  const {
    startTime,
    endTime,
    replayStarttime,
    replayEndtime,
    purchaseStarttime,
    replayWeekendEnabled,
    infusionsoftRegistrationFormId,
    infusionsoftFacebookRegistrationFormId,
    infusionsoftGoogleRegistrationFormId,
    infusionsoftFeaturedGiftRegistrationFormId,
    landingVideoLoop,
    landingVideo,
    salesVideo,
  } = data.site.siteMetadata.conferenceInformation;

  const purchaseStartTime = new Date(
    purchaseStarttime.year,
    purchaseStarttime.month,
    purchaseStarttime.day,
    purchaseStarttime.hour
  );
  const conferenceStartTime = new Date(
    startTime.year,
    startTime.month,
    startTime.day,
    startTime.hour
  );
  const conferenceEndTime = new Date(
    endTime.year,
    endTime.month,
    endTime.day,
    endTime.hour
  );
  const replayStartTime = new Date(
    replayStarttime.year,
    replayStarttime.month,
    replayStarttime.day,
    replayStarttime.hour
  );
  const replayEndTime = new Date(
    replayEndtime.year,
    replayEndtime.month,
    replayEndtime.day,
    replayEndtime.hour
  );

  const purchaseStartTimeFormatted = format(purchaseStartTime, 'MMM do yyyy');

  const hasConferenceStarted = isPast(conferenceStartTime);
  const hasPurchaseStarted = isPast(purchaseStartTime);
  const hasConferenceEnded = isPast(conferenceEndTime);
  const hasReplayStarted = isPast(replayStartTime);
  const hasReplayEnded = isPast(replayEndTime);
  const isConferenceLive =
    (hasConferenceStarted && !hasConferenceEnded) ||
    (hasReplayStarted && !hasReplayEnded);

  const conferenceDateString = replayWeekendEnabled
    ? formatConferenceDateString(
        startTime,
        endTime,
        replayStarttime,
        replayEndtime
      )
    : formatConferenceDateString(startTime, endTime);

  const conferenceDateStringWithDays = replayWeekendEnabled
    ? formatConferenceDateString(
        startTime,
        endTime,
        replayStarttime,
        replayEndtime,
        true
      )
    : formatConferenceDateString(
        startTime,
        endTime,
        undefined,
        undefined,
        true
      );

  const isFb = getIsFacebook();
  const isGoogle = getIsGoogle();
  const isFeaturedProduct = window?.location.pathname.includes('webinar') || window?.location.pathname.includes('ebook') || window?.location.pathname.includes('quit-conflict');
  let infusionsoftFormId = infusionsoftRegistrationFormId;
  if (isFb) infusionsoftFormId = infusionsoftFacebookRegistrationFormId;
  if (isGoogle) infusionsoftFormId = infusionsoftGoogleRegistrationFormId;
  if (isFeaturedProduct)
    infusionsoftFormId = infusionsoftFeaturedGiftRegistrationFormId;

  return (
    <SiteMetaContext.Provider
      value={{
        title: data?.site?.siteMetadata?.title,
        uri: data?.site?.siteMetadata?.uri,
        isFb,
        conferenceStartTime,
        conferenceEndTime,
        replayStartTime,
        purchaseStartTimeFormatted,
        hasPurchaseStarted,
        hasConferenceStarted,
        hasConferenceEnded,
        hasReplayStarted,
        hasReplayEnded,
        isConferenceLive,
        replayWeekendEnabled,
        conferenceDateString,
        conferenceDateStringWithDays,
        infusionsoftRegistrationFormId: infusionsoftFormId,
        landingVideoLoop,
        landingVideo,
        salesVideo,
      }}
    >
      {children}
    </SiteMetaContext.Provider>
  );
};

const useSiteMetaContext = () => {
  const context = useContext(SiteMetaContext);
  if (context === undefined) {
    throw new Error(
      'useSiteMetaContext must be used within a SiteContextProvider'
    );
  }
  return context;
};

export default SiteMetaContextProvider;
export { SiteMetaContextProvider, useSiteMetaContext };
