import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import theme from '../utils/theme';

const StyledButton = styled.button`
  font-family: ${({ theme }) => theme.font.families.body};
  font-weight: 700;

  background: ${({ theme }) => theme.colours.primary};
  border: none;
  color: white;
  height: calc(
    ${({ $height }) =>
        $height === 'auto' ? theme.sizes.formControlHeight : $height} / 1.5
  );
  width: calc(
    ${({ $height }) =>
        $height === 'auto' ? theme.sizes.formControlHeight : $height} / 1.5
  );

  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    font-size ${({ theme }) => theme.font.sizes.lg};
    height: ${({ $height }) =>
      $height === 'auto' ? theme.sizes.formControlHeight : $height};
    width: ${({ $height }) =>
      $height === 'auto' ? theme.sizes.formControlHeight : $height};
  `}

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 200rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.5s;
    border-radius: 200rem;
  }
  :hover,
  :focus {
    outline: 0;
    &:after {
      opacity: ${({ disabled }) => (disabled ? 0 : 1)};
    }
  }
`;

const PlayButton = ({
  children,
  className,
  onClick,
  disabled,
  playing,
  height,
  ...otherProps
}) => {
  return (
    <StyledButton
      className={className}
      onClick={onClick}
      disabled={disabled}
      $height={height}
      {...otherProps}
    >
      <span className="material-icons">{playing ? 'pause' : 'play_arrow'}</span>
    </StyledButton>
  );
};

PlayButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  height: PropTypes.string,
};

PlayButton.defaultProps = {
  children: null,
  className: '',
  onClick: null,
  disabled: false,
  height: 'auto',
};

export { PlayButton };
export default PlayButton;
