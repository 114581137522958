import React from 'react';
import styled from 'styled-components';

import { ColourLevel } from '../types';
import SpeakersList, { SpeakersListProps } from './SpeakersList';
import TitleSeperator from './TitleSeperator';

const Day = styled.h2<{ $colour: string }>`
background: ${({ theme }) => theme.colours.primary};
border-radius: 74px;
font-size: 24px;
color: #fff;
padding: 0.75rem 2.5rem;
display: inline-block;
margin: 0 auto;
  && {
    color: #fff;
    margin-bottom: ${({ theme }) => theme.sizes.lvl1};
    text-align: center;
  }
`;

const CenterContainer = styled.div`
  text-align: center;
`

const Description = styled.h2<{ $colour: string }>`
  && {
    text-align: center;
    max-width: 480px;
    width: 100%;
    margin: 0 auto ${({ theme }) => theme.sizes.lvl4};
    color: ${({ $colour }) => $colour};
  }
`;

interface SpeakersListAndTitleProps extends SpeakersListProps {
  title?: string;
  titleColour?: string;
  description?: string;
  day?: string;
}

const SpeakersListAndTitle = ({
  className,
  speakers,
  title,
  description,
  day,
  titleColour = 'black',
  colourLevel = ColourLevel.SECONDARY,
  hideBuyButton,
  date,
}: SpeakersListAndTitleProps) => {
  return (
    <div className={className}>
      <CenterContainer>
        {day && <Day $colour={titleColour}>Conference Day {day}</Day>}
      </CenterContainer>
      {description && (
      <Description $colour={titleColour} className="h4">
        {description}
      </Description>
      )}
      <CenterContainer>
      {title && <TitleSeperator text={title} colour={titleColour} />}
      </CenterContainer>
      <SpeakersList
        colourLevel={colourLevel}
        hideBuyButton={hideBuyButton}
        date={date}
        speakers={speakers}
      />
    </div>
  );
};

export default SpeakersListAndTitle;
