export const plans = {
  ssc: [
    {
      id: 'online',
      productId: 49,
      name: 'Sleep Super Conference Online Access Only',
      oldPrice: 159,
      price: 99,
      requiresAddress: false,
      soldOut: false,
    },
    {
      id: 'onlineflash',
      productId: 51,
      name: 'Sleep Super Conference Online Access + Flash Drive',
      oldPrice: 199,
      price: 139,
      requiresAddress: true,
      soldOut: false,
    },
    {
      id: 'flash',
      productId: 53,
      name: 'Anxiety Super Conference Flash Drive Only',
      oldPrice: 199,
      price: 139,
      requiresAddress: true,
      soldOut: false,
    },
  ],
  asc: [
    {
      id: 'online',
      productId: 55,
      name: 'Anxiety Super Conference Online Access Only',
      oldPrice: 397,
      price: 197,
      requiresAddress: false,
      soldOut: false,
    },
    {
      id: 'onlineflash',
      productId: 57,
      name: 'Anxiety Super Conference Online Access + Flash Drive',
      oldPrice: 447,
      price: 239,
      requiresAddress: true,
      soldOut: true,
    },
    {
      id: 'flash',
      productId: 59,
      name: 'Anxiety Super Conference Flash Drive Only',
      oldPrice: 447,
      price: 239,
      requiresAddress: true,
      soldOut: true,
    },
    {
      id: 'oto_fb',
      productId: 61,
      name: 'Anxiety Super Conference Flash Drive Only',
      oldPrice: 447,
      price: 219,
      requiresAddress: true,
      soldOut: false,
    },
    {
      id: 'oto_all',
      productId: 63,
      name: 'Anxiety Super Conference Flash Drive Only',
      oldPrice: 447,
      price: 219,
      requiresAddress: true,
      soldOut: false,
    },
    {
      id: 'old_online',
      productId: 39,
      name: 'Anxiety Super Conference Flash Drive Only',
      oldPrice: 447,
      price: 219,
      requiresAddress: true,
      soldOut: false,
    },
    {
      id: 'old_online_flash',
      productId: 41,
      name: 'Anxiety Super Conference Flash Drive Only',
      oldPrice: 447,
      price: 219,
      requiresAddress: true,
      soldOut: false,
    },
  ],
  rsc: [
    {
      id: 'online',
      productId: 29,
      name: 'Relationship Super Conference Online Access Only',
      oldPrice: 119,
      price: 59,
      requiresAddress: false,
      soldOut: false,
    },
    {
      id: 'onlineflash',
      productId: 31,
      name: 'Relationship Super Conference Online Access + Flash Drive',
      oldPrice: 139,
      price: 99,
      requiresAddress: true,
      soldOut: true,
    },
    {
      id: 'flash',
      productId: 33,
      name: 'Relationship Super Conference Flash Drive Only',
      oldPrice: 139,
      price: 99,
      requiresAddress: true,
      soldOut: true,
    },
  ]
};

export default plans;
