import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {PubSub} from "../fe_modules/pubsub"
import Topics from "../fe_modules/pubsub-topics"
import Button from './Button';
import TextInput from './TextInput';
import {Container} from "../layout/Container";

const ErrorText = styled.p`
  font-size: ${({ theme }) => theme.sizes.lvl2};
  color: ${({ theme }) => theme.colours.signal.error};
  margin: 0 ${({ theme }) => theme.sizes.lvl1};
`;

const SignupForm = (props) => {
  const {t} = useTranslation();

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('common:registrationForm.firstName.validationMsg'))
      .trim()
      .label(t('common:registrationForm.firstName.label')),
    lastName: yup
      .string()
      .required(t('common:registrationForm.lastName.validationMsg'))
      .trim()
      .label(t('common:registrationForm.lastName.label')),
    password: yup
      .string()
      .required(t('common:loginForm.password.validationMsg'))
      .min(6, t('common:loginForm.password.validationMinMsg'))
      .label(t('common:loginForm.password.label')),
    email: yup
      .string()
      .required(t('common:registrationForm.email.validationMsg'))
      .email(t('common:registrationForm.email.validationMsg'))
      .trim()
      .label(t('common:registrationForm.email.label')),
  });

  const defaultValues = {
    firstName: props.firstName,
    lastName: props.lastName,
    email: props.email,
    password: '',
  };

  const { register, handleSubmit, errors, setError } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [disableButton, setDisableButton] = useState(false)
  PubSub.subscribe(Topics.STOP_LOADER, () => setDisableButton(false))

  const onSubmit = async (payload) => {
    setDisableButton(true)
    PubSub.publish(Topics.START_LOADER)

    try {
      PubSub.publish(Topics.REGISTER, payload)
      props.afterSignup && props.afterSignup()
    } catch (error) {
      console.log(error);
      setError('form', { message: error.message });
    }
  };

  return (
    <Container>
      <h3 className="text-center py-12 text-3xl sm:text-5xl font-bold">
        {props.title}
      </h3>

      <div className="flex flex-col items-center justify-center bg-secondary p-12">
        <h5 className="text-center font-medium text-white">
          {props.subtitle}
        </h5>
        <form className="flex flex-col w-full md:w-3/4 lg:w-1/2 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              name="firstName"
              placeholder={t('common:registrationForm.firstName.placeholder')}
              ref={register}
              aria-label={t('common:registrationForm.firstName.label')}
              fluid
              error={errors.firstName?.message}
            />

            <TextInput
              name="lastName"
              placeholder={t('common:registrationForm.lastName.placeholder')}
              ref={register}
              aria-label={t('common:registrationForm.lastName.label')}
              fluid
              error={errors.lastName?.message}
            />

            <TextInput
              className={"disabled:opacity-75"}
              name="email"
              placeholder={t('common:registrationForm.email.placeholder')}
              aria-label={t('common:registrationForm.email.label')}
              ref={register}
              fluid
              disabled={props.disableEmail}
              error={errors.email?.message}
              type="email"
            />

            <TextInput
              name="password"
              placeholder={t('common:loginForm.password.placeholder')}
              aria-label={t('common:loginForm.password.label')}
              type="password"
              ref={register}
              fluid
              error={errors.password?.message}
            />
          <div className="text-white text-center">
            <p className="text-sm">
              {t('login.signUp.alreadyHaveAccount')} {' '}
              <Link className="font-bold hover:underline" to="/login">{t('login.signUp.clickHereToLogin')}</Link>
              .
            </p>

            <p className="text-sm" dangerouslySetInnerHTML={{__html: t('login.signUp.disclaimer')}}></p>
          </div>

          <Button fluid type="submit" disabled={disableButton}>
            {props.submitButtonText}
          </Button>

          {errors && errors.form && (
            <ErrorText>{errors.form?.message}</ErrorText>
          )}
        </form>
      </div>
    </Container>
  );
};

SignupForm.propTypes = {
  children: PropTypes.any,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  submitButtonText: PropTypes.string,
  afterSignup: PropTypes.func,
  disableEmail: PropTypes.bool
};

SignupForm.defaultProps = {
  children: undefined,
  title: <>Please create an account to purchase <b>access to the conference</b>.</>,
  subtitle: 'We will email you your login details for ongoing access.',
  submitButtonText: 'Next Step',
  firstName: '',
  lastName: '',
  email: '',
  disableEmail: false
};

export default SignupForm;
