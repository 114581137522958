import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from './Button';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  flex: 1;
  padding-right: ${({ theme }) => theme.sizes.lvl1};
  &:last-child {
    padding-right: 0;
  }
`;

const StyledButton = styled(Button)`
  && {
    font-weight: 400;
    padding: ${({ theme }) => theme.sizes.lvl0}
      ${({ theme }) => theme.sizes.lvl1};
    strong {
      white-space: nowrap;
    }
    ${({ variant, theme }) =>
      variant === 'filled'
        ? `color:${theme.colours.secondaryDark};border:3px ${theme.colours.secondaryDark} solid;`
        : ''};
  }
`;

const TabButtons = ({ className, tabs, onClick, value }) => {
  return (
    <Wrapper className={className}>
      {tabs.map((tab) => (
        <ButtonWrapper key={`tab_${tab.id}`}>
          <StyledButton
            fluid
            variant={value === tab.id ? 'FILLED' : 'OUTLINE'}
            colourLevel="PRIMARY"
            onClick={() => onClick(tab.id)}
          >
            {tab.label}
          </StyledButton>
        </ButtonWrapper>
      ))}
    </Wrapper>
  );
};

TabButtons.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.node,
    })
  ),
};

TabButtons.defaultProps = {
  className: '',
  value: '',
  onClick: null,
  tabs: [],
};

export default TabButtons;
