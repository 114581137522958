import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {ColourLevel} from "../../types";

import Container from '../../layout/Container';
import SalesOptions from './salesOptions';
import SpeakersListAndTitle from '../../components/SpeakersListAndTitle';

const TitleBackground = styled.div`
  background-color: #f5fafd;
`;
const MainTitle = styled.h1`
  && {
    font-weight: 400;
    color: ${({ theme }) => theme.colours.black};
    padding: ${({ theme }) => theme.sizes.lvl6} 0;
    font-family: ${({ theme }) => theme.font.families.body};
    text-align: center;
    font-size: ${({ theme }) => theme.font.sizes.md};
    margin-bottom: 0;
    ${({ theme }) => theme.breakpoint('tabletPortrait')`
      font-size: ${theme.font.sizes.lg};
  `}
    ${({ theme }) => theme.breakpoint('desktop')`
      font-size: ${theme.font.sizes.xxxl};
  `}
  }
`;

const ExpertsBackground = styled.div`
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
  padding-bottom: ${({ theme }) => theme.sizes.lvl10};
`;

const BonusSpeakersListAndTitle = styled(SpeakersListAndTitle)`
  margin-top: ${({ theme }) => theme.sizes.lvl4};
  ${({ theme }) => theme.breakpoint('desktop')`
    margin-top: 0;
  `}
`;

const IPadImpressionImageWrapper = styled(Container)`
  padding-bottom: 0;
  padding-top: 0;
  margin-top: -${({ theme }) => theme.sizes.lvl10};
`;
const IPadImpressionImageContainer = styled.div`
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
`;

const SalesExpertsSection = () => {
  const {t} = useTranslation();

  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "Day" }, data: {Session: {elemMatch: {data: {Speaker: {elemMatch: {data: {Avatar: {id: {ne: null}}}}}}}}}}
        sort: { fields: data___Date }
      ) {
        nodes {
          id
          data {
            Name
            ShortName
            LongName
            Date
            Session {
              id
              data {
                Name
                Slug
                DateOrder
                Category
                BonusSession
                Content {
                  childMarkdownRemark {
                    html
                  }
                }
                Speaker {
                  id
                  data {
                    Name
                    WebsiteUri
                    Avatar {
                      localFiles {
                        childImageSharp {
                          gatsbyImageData(width: 320)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const dates = allAirtable?.nodes;

  return (
    <div>
      <TitleBackground>
        <MainTitle className="h1" dangerouslySetInnerHTML={{__html: t('sales.expertsSection.title')}}></MainTitle>
      </TitleBackground>
      {dates
        .sort(
          (dateA, dateB) =>
            new Date(dateA.data.Date) - new Date(dateB.data.Date)
        )
        .map(({ data, id }, index) => {
          const bonusSpeakers = data.Session.map(
            ({ data: sessionData }) => sessionData
          )
            .filter(({ BonusSession }) => BonusSession)
            .sort(
              (sessionA, sessionB) => sessionA.DateOrder - sessionB.DateOrder
            )
            .map(({ Speaker, Name, Slug, Content, Category }) => ({
              id: Speaker[0].id,
              name: Speaker[0].data.Name,
              tagline: Name,
              image: Speaker[0].data.Avatar.localFiles[0],
              slug: Slug,
              content: Content,
              category: Category,
            }));
          const normalSpeakers = data.Session.map(
            ({ data: sessionData }) => sessionData
          )
            .filter(({ BonusSession }) => !BonusSession)
            .filter(({ Speaker }) => Speaker.length)
            .sort(
              (sessionA, sessionB) => sessionA.DateOrder - sessionB.DateOrder
            )
            .map(({ Speaker, Name, Slug, Content, Category }) => ({
              id: Speaker[0].id,
              name: Speaker[0].data.Name,
              tagline: Name,
              image: Speaker[0].data.Avatar.localFiles[0],
              slug: Slug,
              content: Content,
              category: Category,
            }));

          return (
            <>
              <ExpertsBackground key={`experts_${id}`}>
                <Container>
                  <SpeakersListAndTitle
                    day={index + 1}
                    title={`"${data.ShortName}"`}
                    speakers={normalSpeakers}
                    colourLevel={ColourLevel.TERTIARY}
                    hideBuyButton
                  />
                  {bonusSpeakers && bonusSpeakers.length > 0 && (
                    <BonusSpeakersListAndTitle
                      title="Bonus Sessions"
                      speakers={bonusSpeakers}
                      colourLevel={ColourLevel.TERTIARY}
                      hideBuyButton
                    />
                  )}
                </Container>
              </ExpertsBackground>
              <IPadImpressionImageWrapper>
                <IPadImpressionImageContainer>
                <StaticImage src="../../images/sales_ipad_render.png" alt={t('sales.ipadImageAlt')} />
                </IPadImpressionImageContainer>
              </IPadImpressionImageWrapper>
              <SalesOptions />
            </>
          );
        })}
    </div>
  );
};

export default SalesExpertsSection;
