const redirectAfterAuth = ({isConferenceLive, isPaid}) => {

    // since most of the time the site is up
    // there is no conference playing
    // so send free users to the thank you page
    // to consume their free gifts
    let redirectPath = '/thankyou';

    // During conference:
    if(isConferenceLive) {
        redirectPath = '/agenda'
    }

    // Always send paid users to the agenda page
    if(isPaid) {
        redirectPath = '/agenda'
    }

    return redirectPath;
}

export default redirectAfterAuth;