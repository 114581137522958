import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {useTranslation} from 'gatsby-plugin-react-i18next';


import Container from '../../layout/Container';
import Avatar from '../../components/Avatar';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.secondary};
  color: ${({ theme }) => theme.colours.white};
`;

const Inner = styled(Container)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    text-align: center;
  `}
`;

const Paragraph = styled.p`
  && {
    line-height: 1.7;
    margin: 0 0 ${({ theme }) => theme.sizes.lvl5};
    font-size: ${({ theme }) => theme.font.sizes.xl};
    font-weight: 400;
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      font-size: ${({ theme }) => theme.font.sizes.xxxl};
    `}
    u {
      text-underline-position: under;
    }
    strong {
      font-weight: 700;
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  border-radius: 100%;
  box-shadow: 0 0 0 3px ${({ theme }) => theme.colours.secondary},
    0 0 0 6px ${({ theme }) => theme.colours.white};
  margin: 0 0 ${({ theme }) => theme.sizes.lvl3};
`;

const LandingJoinAlexSection = () => {
  const { alexportrait } = useStaticQuery(
    graphql`
      query {
        alexportrait: file(relativePath: { eq: "alex-howard-portrait.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
    `
  );

  const {t} = useTranslation();

  return (
    <Background>
      <Inner>
        <StyledAvatar image={alexportrait} />

        <Paragraph dangerouslySetInnerHTML={{__html: t('indexAlt.joinAlexSection.description')}}> 
        </Paragraph>
        <StaticImage src="../../images/arrow_white.png" width={30} />
      </Inner>
    </Background>
  );
};

export default LandingJoinAlexSection;
