import React, {useEffect} from 'react';
import {navigate, graphql} from 'gatsby';
import {useTranslation} from "gatsby-plugin-react-i18next";

import {useUserContext} from "../context/UserContext";
import {onAuthStateChanged} from 'firebase/auth';
import {setDoc} from 'firebase/firestore';
import Header from '../components/LandingHeader';
import SignupForm from "../components/SignupForm"
import Layout from '../layout/Layout';
import Seo from "../components/Seo";
import { useSiteMetaContext } from '../context/SiteMetaContext';
import redirectAfterAuth from '../utils/redirectJourney';

const AccessPage = ({location}) => {
    const {t} = useTranslation();
    const siteMetaContext = useSiteMetaContext()
    const {firebase} = useUserContext();

    // thankyou
    // http://localhost:8000/thankyou/?contactId=2193917&inf_contact_key=03e357400cb7103d274dbb08689bd610850050e7915f41da51043c293c2d487f&inf_field_BrowserLanguage=en-GB%2Cen%3Bq%3D0.9&inf_field_FirstName=aaron&inf_field_Email=aaron%2B1652410638008%40alexhoward.tv
    // access
    // http://localhost:8000/access/?inf_field_PostalCode=60000&inf_field_FirstName=aaron&inf_field_Country=Malaysia&inf_field_StreetAddress1=P-1-1&inf_field_Email=aaron%2B1655700115689%40alexhoward.tv&inf_field_StreetAddress2=&inf_field_Country2=null&inf_field_City2=null&inf_field_State=kl&inf_field_MiddleName=null&inf_field_City=Kl&inf_field_PostalCode2=null&inf_field_State2=null&inf_field_Company=&inf_field_Address2Street2=null&inf_field_Address2Street1=null&inf_field_LastName=m&orderId=56081

    // e.g. http://localhost:8000/access?inf_field_PostalCode=60000&inf_field_FirstName=aaron&inf_field_Country=Malaysia&inf_field_StreetAddress1=P-1-1&inf_field_Email=aaron%2B1652410638008%40alexhoward.tv&inf_field_StreetAddress2=&inf_field_Country2=null&inf_field_City2=null&inf_field_State=selangor&inf_field_MiddleName=null&inf_field_City=Kuala+lumpur&inf_field_PostalCode2=null&inf_field_State2=null&inf_field_Company=&inf_field_Address2Street2=null&inf_field_Address2Street1=null&inf_field_LastName=m&orderId=55497
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    useEffect(() => {
        onAuthStateChanged(firebase.auth, (user) => {
            if (params && params.orderId) {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    const customerRef = firebase.customer(user.uid)
                    setDoc(customerRef,
                        {
                            paid: true,
                            orderId: params.orderId
                        },
                        {merge: true}
                    ).then(() => {
                        const redirectUrl = redirectAfterAuth(siteMetaContext)
                        navigate(redirectUrl)
                    });

                    // ...
                } else {
                    // User is signed out
                    console.log('Signed Out')
                }
            }
        });
    }, [params, firebase])

    return (
        <Layout>
            <Header/>
            <Seo title={t('access.meta.title')}/>
            <div className="mt-10">
                <SignupForm
                    title={<>Create an account to <b>access to the conference content</b>.</>}
                    subtitle={'You will be redirected the conference agenda from here'}
                    submitButtonText={'Get Access'}
                    firstName={params.inf_field_FirstName || ''}
                    lastName={params.inf_field_LastName || ''}
                    email={params.inf_field_Email || ''}
                    disableEmail={!!params.inf_field_Email}
                    afterSignup={async () => {
                        navigate('/agenda')
                    }}
                />
            </div>
        </Layout>
    );
};

export default AccessPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

