import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';

import { useUserContext } from '../../context/UserContext';
import Button from '../Button';
import TextInput from '../TextInput';
import Loader from '../Loader';

const ErrorText = styled.p`
  font-size: ${({ theme }) => theme.sizes.lvl2};
  color: ${({ theme }) => theme.colours.signal.error};
  margin: 0 ${({ theme }) => theme.sizes.lvl1};
`;

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Must be a valid email')
    .trim()
    .label('Your email'),
});

const DeletePaidCustomerForm = () => {
  const [loading, setLoading] = useState(false);
  const [deletedCustomer, setDeletedCustomer] = useState();
  const { deletePaidCustomer } = useUserContext();
  const defaultValues = {
    email: '',
  };
  const { register, handleSubmit, errors, setError, reset } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset]);

  const onSubmit = async ({ email }) => {
    setDeletedCustomer(false);
    if (loading) {
      return;
    }
    setLoading(true);

    try {
      await deletePaidCustomer({
        email,
      });
      setDeletedCustomer(true);
    } catch (error) {
      console.log(error);
      setError('form', { message: error.message });
    }

    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>Delete Paid Customer</h2>
        <TextInput
          name="email"
          placeholder="Email address"
          aria-label="Email address"
          ref={register}
          error={errors.email?.message}
        />

        <Button type="submit" disabled={loading}>
          Delete
        </Button>

        {loading && <Loader />}

        {errors && errors.form && (
          <ErrorText>ERROR: {errors.form?.message}</ErrorText>
        )}

        {deletedCustomer && <span>Paid customer successfully deleted</span>}
      </form>
    </>
  );
};

export default DeletePaidCustomerForm;
