import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { Link } from 'gatsby';

import { getFirebase } from '../../services/firebase';
import RegisteredLayout from '../../layout/RegisteredLayout';
import Seo from '../../components/Seo';
import Button from '../../components/Button';
import PaidContent from '../../components/PaidContent';
import BuyNowCtaSection from '../../sections/buyNowCta';
import YourHostSection from '../../sections/yourHost';
import SponsorsSection from '../../sections/sponsors';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const ExtraPadded = styled.div`
  padding: ${({ theme }) => theme.sizes.lvl5} ${({ theme }) => theme.sizes.lvl2};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.breakpoint('phoneLarge')`
    padding: ${({ theme }) => theme.sizes.lvl5}
      ${({ theme }) => theme.sizes.lvl3};
  `}
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    padding: ${({ theme }) => theme.sizes.lvl7}
      ${({ theme }) => theme.sizes.lvl5};
  `}
  ${({ theme }) => theme.breakpoint('desktop')`
    padding: ${({ theme }) => theme.sizes.lvl10}
      ${({ theme }) => theme.sizes.lvl20};
  `}
`;

const VideoIntroContainer = styled(ExtraPadded)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.sizes.lvl2};
`;
const VideoBackground = styled.div`
  background: repeating-linear-gradient(
    ${({ theme }) => theme.colours.white},
    ${({ theme }) => theme.colours.white} 15%,
    ${({ theme }) => theme.colours.backgroundPaper} 15.1%,
    ${({ theme }) => theme.colours.backgroundPaper} 100%
  );
`;
const VideoContainer = styled(ExtraPadded)`
  padding-top: 0;
  padding-bottom: 0;
`;
const ReactPlayerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 60%;
  margin-bottom: ${({ theme }) => theme.sizes.lvl3};
  ${({ theme }) => theme.breakpoint('medium')`
    padding-top: 50%;
  `}
  ${({ theme }) => theme.breakpoint('giant')`
    padding-top: 40%;
  `}

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const PositionedButton = styled(Button)`
  && {
    max-width: 420px;
    width: 100%;
    margin-top: ${({ theme }) => theme.sizes.lvl1};
    flex-direction: row;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.sizes.lvl2};
    p {
      padding: 0px ${({ theme }) => theme.sizes.lvl1} 0px 0px;
      margin: 0px;
    }
  }
`;

const Content = styled.div`
  && {
    margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  }
`;

const InlineLink = styled.a`
  text-decoration: none;
  width: 100%;
  margin: 0 auto ${({ theme }) => theme.sizes.lvl1};
  display: flex;
  justify-content: center;
`;

const ErrorContainer = styled.div`
  background-color: transparent;
  padding-top: ${({ theme }) => theme.sizes.lvl4};
  padding-bottom: ${({ theme }) => theme.sizes.lvl3};
`;

const ErrorText = styled.p`
  margin: 0;
  padding: 0;
`;

const ErrorLink = styled(Link)`
  color: ${({ theme }) => theme.colours.primary};
`;

const AudioLink = ({ fileName }) => {
  const {t} = useTranslation();
  const { getAudioDownloadLink } = getFirebase();
  const [audioLink, setAudioLink] = useState(undefined);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = fileName ? await getAudioDownloadLink(fileName) : undefined;
        setAudioLink(res);
      } catch (e) {
        return;
      }
    }
    fetchData();
  }, [fileName, getAudioDownloadLink]);

  const inner = (
    <PositionedButton colourLevel="SECONDARY" variant="OUTLINE">
      <p>{ t('common:downloadAudio') }</p>
    </PositionedButton>
  );
  if (!audioLink) {
    return <></>;
  }

  return (
    <InlineLink href={audioLink} download={fileName} target="_blank">
      {inner}
    </InlineLink>
  );
};
const TranscriptLink = ({ fileName }) => {
  const {t} = useTranslation();
  const { getTranscriptDownloadLink } = getFirebase();
  const [transcriptLink, setTranscriptLink] = useState(undefined);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = fileName
          ? await getTranscriptDownloadLink(fileName)
          : undefined;
        setTranscriptLink(res);
      } catch (e) {
        return;
      }
    }
    fetchData();
  }, [fileName, getTranscriptDownloadLink]);

  const inner = (
    <PositionedButton colourLevel="SECONDARY" icon="file_present">
      <p>{ t('common:downloadTranscript') }</p>
    </PositionedButton>
  );

  if (!transcriptLink) return <></>;

  return (
    <>
      <InlineLink href={transcriptLink} download={fileName} target="_blank">
        {inner}
      </InlineLink>
    </>
  );
};

const VideoPage = ({ data, freeDate }) => {
  const {t} = useTranslation();

  return (
    <RegisteredLayout>
      <Seo title="Video" />

      <VideoIntroContainer>
        <h1 className="h2">{data.Name}</h1>
        {data.Tagline?.childMarkdownRemark?.html && (
          <Content
            className="p"
            dangerouslySetInnerHTML={{
              __html: data.Tagline?.childMarkdownRemark.html,
            }}
          />
        )}
      </VideoIntroContainer>
      <VideoBackground>
        <VideoContainer>
          <PaidContent
            freeDate={freeDate}
            alwaysFree={data.Gift}
            notPaid={
              <ErrorContainer>
                <ErrorText>
                  <b>
                   {t('common:toViewBuy')}
                    <ErrorLink to="/sales">{t('common:here')}</ErrorLink>, {t('common:orClickBuyNow')}.
                  </b>
                </ErrorText>
              </ErrorContainer>
            }
          >
            <ReactPlayerContainer>
              <ReactPlayer
                className="react-player"
                url={data.Video}
                controls
                width="100%"
                height="100%"
              />
            </ReactPlayerContainer>
          </PaidContent>

          <PaidContent 
            freeDate={freeDate} notPaid={<></>}>
            <>
              {data.Transcript && <TranscriptLink fileName={data.Transcript} />}
              {data.Audio && <AudioLink fileName={data.Audio} />}
            </>
          </PaidContent>
        </VideoContainer>
      </VideoBackground>

      {data.Speaker &&
        data.Speaker[0].data.Bio &&
        data.Speaker[0].data.Avatar && (
          <YourHostSection
            showHeader={false}
            speaker={data.Speaker[0].data}
            withWebsite={true}
          />
        )}
      <BuyNowCtaSection />

      <SponsorsSection />
    </RegisteredLayout>
  );
};

export default VideoPage;