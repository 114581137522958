import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import { useSiteMetaContext } from '../context/SiteMetaContext';
import Layout from '../layout/Layout';
import Seo from '../components/Seo';
import ScrollTarget from '../components/ScrollTarget';
import {isASC, isRSC} from "../utils"
import LandingIntroSection from '../sections/landing/landingIntro';
import HomepageExpertsSection from '../sections/homepageExperts';
import LandingAdditionalContentSection from '../sections/landing/landingAdditionalContent';
import LandingGiftsMoreDetailsSection from '../sections/landing/landingGiftsMoreDetails';
import YourHostSection from '../sections/yourHost';
import WhatIsSuperConferenceSection from '../sections/whatIsSuperConference';
import ConferenceContentSection from '../sections/conferenceContent';
import RegistrationSection from '../sections/registration';
import StickyRegistration from '../sections/stickyRegistration';
import SponsorsSection from '../sections/sponsors';
import FeaturedGiftSection from '../sections/featuredGift';
import FeaturedGiftTwoColumns from '../sections/featuredGiftTwoColumns';
import SpeakersCarouselSection from '../sections/speakersCarousel';
import TooltipSvgInner from '../components/TooltipSvgInner';
import GiftSvgPath from '../images/gift.svg';

const RegistrationMobileOnly = styled(RegistrationSection)`
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: none;
  `};

  h1 {
    font-size: 2.25rem;
    font-weight: bold;
  }
`;

const RegistrationDesktopOnly = styled(RegistrationSection)`
  display: none;
  ${({theme}) => theme.breakpoint('tabletLandscape')`
    display: block;
  `};
`;

const PaperBackground = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.white};
`;

const IndexPage = ({ data, location }) => {
  const {t} = useTranslation();
  const {conferenceDateString, title} = useSiteMetaContext();
  const { allAirtable, landingBg, landingBgMobile } = data;
  // Affiliate Id is returned from infusionsoft's redirect. We store it here so we can post it when creating firebase registration later.
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.affiliate) {
    localStorage.setItem('superconferenceAffiliate', params.affiliate);
  }
  const registrationTooltipInner = (
    <TooltipSvgInner
        content={
            <>
                { t('index.registrationTooltip.part1') }
                <br/>
                { t('index.registrationTooltip.part2') }
            </>
        }
    >
        <GiftSvgPath className="w-8 h-8"/>
    </TooltipSvgInner>
  );

  const registrationSubtitle = (
    <div>
        {t('index.registration.subtitle')} {title} <br/>
        {conferenceDateString}
    </div>
  );

  const speakers =
    allAirtable?.edges
      ?.map((edge) => edge?.node)
      .filter(({ data }) => !!data.Speaker)
      .sort(
        (speakerA, speakerB) =>
          speakerA.data.ExpertOrder - speakerB.data.ExpertOrder
      )
      .map(({ data }) => ({
        id: data.Speaker[0].id,
        name: data.Speaker[0].data.Name,
        tagline: data.Name,
        image: data.Speaker[0].data.Avatar.localFiles[0],
        slug: data.Slug,
        content: data.Content,
        category: data.Category,
      })) || [];

  return (
    <Layout>
      <Seo title={t('indexFeaturedGift.meta.title')} />


      <LandingIntroSection
        title={
          <div  className="hidden lg:block" style={{fontSize: "68px"}} dangerouslySetInnerHTML={{__html: t('indexFeaturedGift.hero.title')}}>
          </div>
        }
        subtitle={
          <div className={'lg:w-5/6 w-full hidden lg:block'}>
            <h1 style={{fontSize: "1rem"}} dangerouslySetInnerHTML={{__html: t('indexFeaturedGift.hero.body')}}></h1>
          </div>
        }
        buttonLabel={t('indexFeaturedGift.registrationButtonLabel')}
        landingBg={landingBg}
        landingBgMobile={landingBgMobile}
        hideVideo
        hideNav
      />

      <RegistrationDesktopOnly
          inline
          tooltipInner={registrationTooltipInner}
      />

      <RegistrationMobileOnly
        title={t('indexFeaturedGift.hero.title')}
        primaryButtonLabel={t('indexFeaturedGift.registrationButtonLabel')}
        tooltipInner={registrationTooltipInner} />

      {isASC && ( <FeaturedGiftTwoColumns /> )}

      {isRSC && ( <FeaturedGiftSection /> )}

      <ScrollTarget id="signup" />
      <StickyRegistration
        className={'lg:flex hidden'}
        title={<span style={{textTransform:"none", fontWeight:"700"}}>{t('indexFeaturedGift.stickyRegistration.title')}</span>}
        primaryButtonLabel={t('indexFeaturedGift.stickyRegistration.primaryButtonLabel')}
        tooltipInner={registrationTooltipInner}
      />

      <Background>
        <SpeakersCarouselSection
          speakers={speakers}
          pretitle={
            <div dangerouslySetInnerHTML={{__html: t('indexFeaturedGift.speakersCarousel.preTitle')}}></div>
          }
          title={
            <div style={{fontWeight: "700"}}>
             {t('indexFeaturedGift.speakersCarousel.title')}
              <br />
              {title}
            </div>
          }
          subtitle={
            <div dangerouslySetInnerHTML={{__html: t('indexFeaturedGift.speakersCarousel.subtitle')}}>
            </div>
          }
          svgPath
        />
      </Background>

      <ConferenceContentSection />

      <WhatIsSuperConferenceSection />

      <PaperBackground>
        <HomepageExpertsSection />
      </PaperBackground>

      <LandingAdditionalContentSection />

      <LandingGiftsMoreDetailsSection />

      <RegistrationSection
          inline
          title={<strong>{t('indexFeaturedGift.hero.title')}</strong>}
          subtitle={registrationSubtitle}
          tooltipInner={registrationTooltipInner}
      />

      <YourHostSection showHeader />

      <SponsorsSection />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    landingBg: file(relativePath: { eq: "landing_bg_meditation.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    landingBgMobile: file(
      relativePath: { eq: "landing_bg_meditation_mobile.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allAirtable(
      filter: {
        table: { eq: "Session" }
        data: {
          BonusSession: { ne: true }
          Speaker: {
            elemMatch: {
              data: { MainHost: { ne: true }, Host: { ne: true }, Avatar: {id: {ne: null}} }
            }
          }
        }
      }
    ) {
      edges {
        node {
          data {
            Name
            DateOrder
            BonusSession
            Content {
              childMarkdownRemark {
                html
              }
            }
            Speaker {
              id
              data {
                Name
                WebsiteUri
                ExpertOrder
                Host
                MainHost
                Avatar {
                  localFiles {
                    childImageSharp {
                      gatsbyImageData(width: 400)
                    }
                  }
                }
              }
            }
          }
          id
        }
      }
    }
  }
`;
