import React from 'react';
import {graphql} from 'gatsby';
import RegisteredLayout from '../../layout/RegisteredLayout';
import Seo from '../../components/Seo';

import AgendaDayExpertsSection from '../../sections/agendaDayExperts';

import BuyNowCtaSection from '../../sections/buyNowCta';
import YourHostSection from '../../sections/yourHost';
import SponsorsSection from '../../sections/sponsors';
import {useTranslation} from 'gatsby-plugin-react-i18next';


const DayPage = ({data}) => {
    const {t} = useTranslation();

    return (
        <RegisteredLayout>
            <Seo title={t('agenda.meta.title')} />
            <AgendaDayExpertsSection day={data}/>
            <YourHostSection/>
            <BuyNowCtaSection/>
            <SponsorsSection/>
        </RegisteredLayout>
    );
};

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
            node {
                ns
                data
                language
            }
            }
        }
        airtable(table: { eq: "Day" }, data: { Name: { eq: "Day 4" } }) {
            data {
                Name
                ShortName
                LongName
                Date
                Slug
                Session {
                    id
                    data {
                        Name
                        Slug
                        DateOrder
                        Category
                        BonusSession
                        Content {
                            childMarkdownRemark {
                                html
                            }
                        }
                        Speaker {
                            id
                            data {
                                Name
                                WebsiteUri
                                Avatar {
                                    localFiles {
                                        childImageSharp {
                                            gatsbyImageData(width: 320)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default DayPage;
