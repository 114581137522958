import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useStaticQuery, graphql } from 'gatsby';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Button from './Button';
import TextInput from './TextInput';
import {PubSub} from "../fe_modules/pubsub";
import Topics from "../fe_modules/pubsub-topics";


const ResetPasswordForm = () => {
  const {t} = useTranslation();

  const { site = {} } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            url
          }
        }
      }
    `
  );
  const { url: siteUrl } = site?.siteMetadata;


const schema = yup.object().shape({
  email: yup
      .string()
      .required(t('common:registrationForm.email.validationMsg'))
      .email(t('common:registrationForm.email.validationMsg'))
      .trim()
      .label(t('common:registrationForm.email.label')),
});

  const [sentVerification, setSentVerification] = React.useState('');
  const [disableButton, setDisableButton] = React.useState(false);
  PubSub.subscribe(Topics.STOP_LOADER, () => setDisableButton(false))


  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
      setDisableButton(true)
      PubSub.publish(Topics.RESET_PASSWORD, data)
      setSentVerification(data.email)
  };

  return (
    <div className="w-full">
      <form className="flex flex-col items-center" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full lg:w-1/2 space-y-4">
            <TextInput
              name="email"
              placeholder={t('common:registrationForm.email.placeholder')}
              aria-label={t('common:registrationForm.email.label')}
              ref={register}
              fluid
              error={errors.email?.message}
            />

          {sentVerification && (
            <div className="text-center mx-auto text-white">
              { t('resetPassword.sentResetLinkMsg') }
              <a href={`MAILTO:${sentVerification}`}>{sentVerification}</a>!
            </div>
          )}

            <Button type="submit" fluid disabled={disableButton}>
              {sentVerification ? t('resetPassword.resendLinkButton') : t('resetPassword.sendLinkButton')}
            </Button>

          {errors && errors.form && (
            <div className="text-red-400 font-bold">{errors.form?.message}</div>
          )}
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
