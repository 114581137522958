import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import {StaticImage} from 'gatsby-plugin-image';

import {useSiteMetaContext} from '../../context/SiteMetaContext';
import Container from '../../layout/Container';
import PlayButton from '../../components/PlayButton';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const Background = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.colours.backgroundPaper};
`;

const Inner = styled(Container)`
  position: relative;
  display: flex;
  justify-content: center;
  ${({theme}) => theme.breakpoint('tabletLandscape')`
    text-align: center;
  `}
`;

const VideoWrapper = styled.div`
  max-width: ${({theme}) => theme.sizes.sizeByFactor(94)};
  margin: 0 auto;
  width: 100%;
`;
const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Paragraph = styled.p`
  && {
    line-height: 1.7;
    margin: 0;
    max-width: 50rem;
    font-size: ${({theme}) => theme.font.sizes.xxl};
    font-weight: 400;

    u {
      text-underline-position: under;
    }

    strong {
      font-weight: 700;
    }
  }
`;

const BigTitle = styled.p`
  && {
    line-height: 1.7;
    margin: 0;
    font-size: ${({theme}) => theme.font.sizes.xxxl};
    font-weight: 400;

    u {
      text-underline-position: under;
    }
  }
`;

const Shadow = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
  box-sizing: border-box;
  padding: ${({theme}) => theme.sizes.lvl2};

  ${({theme}) => theme.breakpoint('tabletLandscape')`
    padding: ${({theme}) => theme.sizes.lvl3};
  `}
  :hover::before {
    opacity: 1;
  }

  ::before {
    content: '';
    transition: opacity 0.3s;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const LandingVideoCoverWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  div {
    height: 100%;
  }

  opacity: ${({$hide}) => ($hide ? 0 : 1)};
  pointer-events: ${({$hide}) => ($hide ? 'none' : 'auto')};
  transition: opacity 0.8s ease-in-out;
`;

const PositionedPlayButton = styled(PlayButton)`
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);

  span {
    font-size: 3.5rem;
  }

  ${({theme}) => theme.breakpoint('phoneLarge')`
  top: 50%;
`}
`;

const VideoTitle = styled.strong`
  position: relative;
  color: ${({ theme }) => theme.colours.primary};
  text-transform: uppercase;
  font-size: ${({theme}) => theme.font.sizes.lg};

  ${({theme}) => theme.breakpoint('tabletLandscape')`
   font-size: ${({theme}) => theme.font.sizes.xxl};
  `}
`;

const VideoSubTitle = styled(VideoTitle)`
  display: block;
  line-height: 1;
  color: ${({theme}) => theme.colours.white};
`;

const LandingTrailerSection = () => {
    const {t} = useTranslation();
    const playerRef = useRef(null);
    const {title, landingVideo} = useSiteMetaContext();
    const [playing, setPlaying] = useState(false);

    return (
        <Background>
            <Inner>
                <BigTitle dangerouslySetInnerHTML={{__html: t('index.trailerSection.title')}}>
                </BigTitle>
            </Inner>
            <VideoWrapper>
                <VideoContainer>
                    <ReactPlayer
                        className="react-player"
                        ref={playerRef}
                        allowFullScreen
                        playing={playing}
                        url={landingVideo}
                        controls
                        width="100%"
                        height="100%"
                        config={{
                            vimeo: {
                                playerOptions: {
                                    pip: true,
                                },
                            },
                        }}
                    />
                    <LandingVideoCoverWrapper $hide={playing}>
                        <StaticImage
                            src="../../images/landing_trailer_cover.jpg"
                            alt={`${title} landing video cover`}
                            placeholder="blurred"
                            style={{height: "auto"}}
                        />
                        <Shadow>
                            <VideoTitle className="strong">{title}</VideoTitle>
                            <VideoSubTitle className="strong">
                            { t('index.trailerSection.video.explainedUnder') }
                            </VideoSubTitle>
                        </Shadow>
                        <PositionedPlayButton
                            height="6rem"
                            onClick={() => setPlaying(true)}
                        />
                    </LandingVideoCoverWrapper>
                    {!playing && <></>}
                </VideoContainer>
            </VideoWrapper>
            <Inner>
                <Paragraph dangerouslySetInnerHTML={{__html: t('index.trailerSection.description')}} />
            </Inner>
        </Background>
    );
};

export default LandingTrailerSection;
